export const calendar = {
  namespaced: true,

  state: {
    meet: {
      title: '',
      duration: '',
      start_date: '',
      start_time: '00:00',
      due_date: '',
      due_time: '00:00',
      location: '',
      link: '',
    },
    editStatus: false,
    meetId: false,
    fullscreen: false,
  },

  mutations: {
    GET_MEET(state, data) {
      state.meet = data
    },
    CHANGE_EDIT_STATUS(state, data) {
      state.editStatus = data
    },
    GET_MEET_ID(state, data) {
      state.meetId = data
    },
    CHANGE_FULLSCREEN(state, data) {
      state.fullscreen = data
    },
  },
}
