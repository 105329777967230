import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';

import store from './store';
import router from './router';
import App from './App.vue'; // Global Components
import './global-components'; // 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/acl';
import '@/libs/toastification';
import '@/libs/clipboard'; // import '@/libs/sweet-alerts'
import '@/libs/vue-select';
import '@/libs/tour'; // import '@/libs/firebase'
import '@/libs/tippy';
import '@/libs/font-awesome';
import '@/libs/vue-simple-context-menu'; // import '@/libs/simple-flowchart'
import '@/libs/bootstrap-vue';
import '@/libs/prismjs'; // import '@/libs/mdi-vue'
import '@/libs/vue-draggable';
import i18n from '@/libs/i18n';
import 'animate.css'; // import LogRocket from 'logrocket'

// import LogRocket from 'logrocket'

// LogRocket.init('90q9h3/board')

// import '@/libs/discord'

// Composition API
Vue.use(VueCompositionAPI);

// import Editor from 'vue-editor-js/src/index'
//
// Vue.use(Editor)

// import core styles
// eslint-disable-next-line no-undef
require('@core/scss/core.scss');

// import assets styles
// eslint-disable-next-line no-undef
require('@core/scss/vue/libs/vue-select.scss');
// eslint-disable-next-line no-undef
require('@/assets/scss/style.scss');
// eslint-disable-next-line no-undef
require('@/assets/scss/variables/_variables.scss');
// eslint-disable-next-line no-undef
require('@/assets/scss/tailwind.scss');

export const eventBus = new Vue();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
