<template>
  <div class="mention-items">
    <template v-if="items.length">
      <div
        class="item"
        :class="{'is-selected': index === selectedIndex}"
        v-for="(item, index) in items"
        :key="index"
        @click="selectItem(index)"
      >
        <div class="d-flex">
          <div class="d-flex flex-column">
            <span class="font-weight-bold">{{ item.title }}</span>
            <span class="text-muted font-small-2">@{{ item.username }}</span>
          </div>
        </div>
      </div>
    </template>
    <div class="item" v-else>No result</div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
    }
  },

  watch: {
    items() {
      this.selectedIndex = 0
    },
  },

  methods: {
    onKeyDown({event}) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },

    enterHandler() {
      this.selectItem(this.selectedIndex)
    },

    selectItem(index) {
      const item = this.items[index]

      if (item) {
        this.command({id: item.username})
      }
    },
  },
}
</script>

<style lang="scss">
.mention-items {
  max-height: unset !important;
  max-width: 300px;
  padding: 0.3rem 0 !important;
  position: relative;
  border-radius: 0.4rem !important;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 10px 20px rgba(0, 0, 0, 0.1);

  .item {
    display: block;
    margin: 0;
    width: 100%;
    text-align: left;
    background: transparent;
    padding: 0.3rem 1.4rem;

    .block-icon {
      border-radius: 3px;
      border: 1px solid #ccc;
      height: 30px;
      width: 30px;
    }

    &.is-selected {
      border-color: #000;
    }
  }
}
</style>
