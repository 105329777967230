export default {
  namespaced: true,
  state: {
    user: {
      first_name: '',
      last_name: '',
      role_name: '',
      worker_type: 'user',
      company: [],
    },
    projects: [],
    selectedProject: {
      title: '',
      creator: {
        title: null,
      },
      description: 'You are just one step away from your goal',
      image: null,
      members: [],
    },
    selectedProjects: [],
    users: [
      {
        title: '',
      },
    ],
    selectedUser: {
      title: '',
    },
    sprints: [],
    selectedSprint: {
      goal: '',
      name: '',
    },
    tasks: [],
    preloader: true,
    loading: false,
    timestamp: 0,
    session: {
      project: null,
      company: null,
    },
  },
  mutations: {
    GET_USER(state, val) {
      state.user = val;
    },
    GET_PROJECTS(state, val) {
      state.projects = val;
    },
    SET_PROJECT(state, val) {
      state.selectedProject = val;
    },
    SET_PROJECTS(state, val) {
      state.selectedProjects = val;
    },
    SET_SPRINT(state, val) {
      state.selectedSprint = val;
    },
    SET_SPRINTS(state, val) {
      state.sprints = val;
    },
    SET_SESSION(state, val) {
      state.session = val;
    },
    SET_TIMESTAMP(state, val) {
      state.timestamp = val;
    },
    CLEAR_PROJECT(state) {
      state.selectedProject = {
        title: '',
        creator: {
          title: null,
        },
        members: [],
      };
    },
    CLEAR_PROJECTS(state) {
      state.selectedProjects = [];
    },
    GET_USERS(state, val) {
      state.users = val;
    },
    GET_TASKS(state, val) {
      state.tasks = val;
    },
    USER_SELECTION(state, data) {
      state.selectedUser = data;
    },
    PRELOADER(state, data) {
      state.preloader = data;
    },
    LOADING(state, data) {
      state.loading = data;
    },
  },
  actions: {},
};
