import axiosIns, {auth} from '@/libs/axios';

class SprintsService {
  getSprintsForProject(id) {
    return axiosIns.get('sprint/' + id + '/', {headers: auth()});
  }

  getSprint(id) {
    return axiosIns.get('sprint/get/' + id + '/', {headers: auth()});
  }

  createSprint(data) {
    return axiosIns.post('sprint/create/', data, {headers: auth()});
  }

  deleteSprint(id) {
    return axiosIns.delete('sprint/delete/' + id + '/', {headers: auth()});
  }

  startSprint(id) {
    return axiosIns.get('sprint/start/' + id + '/', {headers: auth()});
  }

  stopSprint(id) {
    return axiosIns.get('sprint/stop/' + id + '/', {headers: auth()});
  }

  addTaskInSprint(id, data) {
    return axiosIns.post('sprint/task/add/' + id + '/', data, {
      headers: auth(),
    });
  }

  removeSprintFromTheTask(id, data) {
    return axiosIns.post('sprint/task/remove/' + id + '/', data, {
      headers: auth(),
    });
  }

  updateSprint(data) {
    return axiosIns.patch('sprint/update/' + data.id + '/', data, {
      headers: auth(),
    });
  }

  // addMembers(id, data) {
  //     return axiosIns.post('project/members/' + id + '/add/', data)
  // }
}

export default new SprintsService();
