<template>
  <b-modal
    v-model="showModal"
    :cancel-title="$t('Cancel')"
    :ok-title="$t('Accept')"
    centered
    content-class="tw-rounded-2xl tw-p-2"
    hide-footer
    hide-header
    hide-header-close
    size="lg"
    @show="onShow"
  >
    <div class="tw-flex tw-items-center tw-justify-between">
      <div class="tw-flex tw-items-center tw-rounded-xl tw-p-1.5 tw-bg-gray-100 tw-transition">
        <button
          :class="{'tw-bg-white tw-shadow tw-text-sky-500': tab === 0}"
          class="tw-flex tw-items-center tw-px-3 tw-py-2 tw-rounded-[6px] tw-transition tw-font-medium tw-leading-[1.2] tw-h-[32px]"
          @click="tab = 0"
        >
          Task
        </button>
        <button
          :class="{'tw-bg-white tw-shadow tw-text-sky-500': tab === 1}"
          class="tw-flex tw-items-center tw-px-3 tw-py-2 tw-rounded-[6px] tw-transition tw-font-medium tw-leading-[1.2] tw-h-[32px]"
          @click="tab = 1"
        >
          Project
        </button>
        <!--        <button-->
        <!--          :class="{'tw-bg-white tw-shadow': tab === 2}"-->
        <!--          class="tw-px-3 tw-py-0.5 tw-rounded-md tw-font-medium tw-transition"-->
        <!--          @click="tab = 2"-->
        <!--        >-->
        <!--          Doc-->
        <!--        </button>-->
      </div>
      <button v-tippy="{content: $t('Close'), delay: [500, 0]}" @click="showModal = false">
        <svg
          class="tw-w-6 tw-h-6"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6 18 18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    </div>
    <template v-if="tab === 0">
      <input
        v-model="projectData.name"
        class="tw-mt-6 tw-bg-transparent tw-px-0 tw-outline-none tw-font-medium tw-w-full tw-text-lg tw-placeholder-gray-400"
        placeholder="Project title"
        type="text"
      />
      <task-tip-tap-editor v-model="projectData.description" :is-show-top-menu="false" class="tw-mt-4" />
    </template>
    <template v-if="tab === 1">
      <input
        v-model="issueData.subject"
        class="tw-mt-6 tw-bg-transparent tw-px-0 tw-outline-none tw-font-medium tw-w-full tw-text-lg tw-placeholder-gray-400"
        placeholder="Issue title"
        type="text"
      />
      <task-tip-tap-editor :is-show-top-menu="false" class="tw-mt-4" />
    </template>
    <div
      :class="tab === 1 ? 'tw-max-h-[0px] tw-opacity-0' : 'tw-max-h-[32px] tw-opacity-100'"
      class="tw-mt-8 tw-flex tw-gap-x-2 tw-transition-[all_0.25s_ease-out] tw-overflow-hidden"
    >
      <UIDropdown v-model="selectedProject" :values="projects" title-button="Project" />
      <UIDropdown v-model="selectedSprint" :values="sprints" title-button="Sprint" />
    </div>
    <div class="tw-mt-8 tw-flex tw-justify-end tw-gap-x-2 tw-w-full">
      <button
        class="tw-inline-flex tw-items-center tw-rounded-lg tw-bg-gray-600 tw-px-3 tw-py-1 tw-text-white tw-shadow-sm hover:tw-bg-gray-500 tw-outline-none"
      >
        Create
      </button>
      <UIDropdown :values="more" title-button="More" />
    </div>
  </b-modal>
</template>

<script>
import {BModal} from 'bootstrap-vue';
import store from '@/store';
import router from '@/router';
import TaskTipTapEditor from '@/components/Task/TaskTipTapEditor.vue';
import UIDropdown from '@/components/UIDropdown.vue';
import UserService from '@/services/user.service';

export default {
  name: 'filterProject',
  components: {
    UIDropdown,
    TaskTipTapEditor,
    BModal,
  },
  data() {
    return {
      sprints: [],
      tab: 0,
      issueData: {
        subject: '',
        description: '',
      },
      projectData: {
        name: '',
        description: '',
      },
      selectedProject: null,
      selectedSprint: null,
      more: [
        {
          title: 'Create and open',
          value: 0,
        },
        {
          title: 'Create and continue edit',
          value: 1,
        },
      ],
    };
  },
  computed: {
    showModal: {
      get: () => store.state.other.showCreateOther,
      set: val => {
        store.commit('other/changeCreateOther', val);
      },
    },
    project: {
      get: () => store.state.osBoard.selectedProject,
      set: val => {
        localStorage.setItem('selected_project', JSON.stringify(val));
        UserService.setSession({
          project: val.id,
          company: 1,
        });
        store.commit('osBoard/SET_PROJECT', val);
      },
    },
    sprint: {
      get: () => store.state.osBoard.selectedSprint,
      set: val => {
        localStorage.setItem('selected_filter_sprint', JSON.stringify(val));
        store.commit('osBoard/SET_SPRINT', val);
      },
    },
    projects() {
      return store.state.osBoard.projects;
    },
  },
  watch: {
    project() {
      if (router.history.current.path !== '/login') this.getSprintsForProject();
      this.sprint = {
        goal: '',
        name: '',
      };
      this.selectedProject = this.project;
    },
  },
  methods: {
    getSprintsForProject() {
      console.log('Create other modal', ' - get sprint');
      if (localStorage.getItem('access_token')) {
        // SprintsService.getSprintsForProject(this.project.id).then(r => {
        //   if (r.status === 200) {
        //     this.sprints = r.data.map(el => ({title: el.goal ? el.goal : `#${el.name}`, id: el.id}));
        //   }
        // });
      }
    },
    async onShow() {
      await this.getSprintsForProject();
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-table {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 15px 0;
  align-items: center;

  p {
    margin-bottom: 0;
  }
}

.filter-clear {
  font-size: 12px;
  color: rgba(220, 53, 69, 0.5);
  cursor: pointer;

  &:hover {
    color: rgba(220, 53, 69, 0.8);
  }
}

.modal-filter {
  color: #1ab7ea;

  .v-select {
    .vs__selected {
      margin-top: 3px !important;
    }
  }
}
</style>

<style lang="scss">
.mr-custom .custom-switch .custom-control-label {
  padding-left: 3rem;
  line-height: 1.7rem;
}
</style>
