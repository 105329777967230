export const sprints = {
  namespaced: true,

  state: {
    sprints: [],
    showModalSprint: false,
    defaultDays: 7,
  },

  mutations: {
    GET_SPRINTS(state, data) {
      state.sprints = data;
    },
    SHOW_MODAL(state, data) {
      state.showModalSprint = data;
    },
  },
};
