<template>
  <b-modal v-model="show" centered hide-footer size="lg" title="Add image">
    <b-tabs v-model="tab" content-class="mt-2" justified>
      <b-tab active title="Upload (Drag 'n' Drop)">
        <!--        <vue-dropzone-->
        <!--          ref="myVueDropzone"-->
        <!--          id="dropzone"-->
        <!--          @vdropzone-success="vfileUploaded"-->
        <!--          :options="dropzoneOptions"-->
        <!--          :include-styling="false"-->
        <!--        >-->
        <!--        </vue-dropzone>-->
        <div
          id="dropzone"
          :class="{'hover-dropzone': isDragging}"
          @dragleave="dragleave"
          @dragover="dragover"
          @drop="onDrop($event)"
        >
          <input
            id="fileInput"
            ref="file"
            accept=".pdf,.jpg,.jpeg,.png"
            class="hidden-input d-none"
            multiple
            name="file"
            type="file"
            @change="onChange"
          />

          <div v-if="files.length" class="preview-container">
            <div v-for="file in files" :key="file.name" class="preview-card">
              <div>
                <img :alt="file.name" :src="generateURL(file)" class="preview-img" />
                <p class="mb-0 p-50 preview-text">
                  {{ file.name }} -
                  {{ Math.round(file.size / 1000) + 'kb' }}
                </p>
              </div>
              <div>
                <button class="ml-1" title="Remove file" type="button" @click="remove(files.indexOf(file))">
                  <feather-icon class="align-text-top" icon="XIcon" size="16" />
                </button>
              </div>
            </div>
          </div>

          <label v-else class="file-label" for="fileInput" text="">
            <div v-if="isDragging">Release to drop files here.</div>
            <div v-else>Drop files here or <u>click here</u> to upload.</div>
          </label>
        </div>
      </b-tab>
      <b-tab title="Link">
        <label text="">Image URL:</label>
        <b-form-input id="url" v-model="singleImageSrc" />
      </b-tab>
      <!--      <b-tab title="Upload (Simple)">-->
      <!--        <label text="">Really simple input upload:</label>-->
      <!--        <b-form-file @change="fileChange" ref="file" />-->
      <!--      </b-tab>-->
      <!--      <b-tab title="UploadCare">-->
      <!--        <uploadcare :publicKey="'44c58b3677539a213057'" @success="onSuccess" @error="onError" multiple imagesOnly>-->
      <!--          <b-button variant="primary">Open</b-button>-->
      <!--        </uploadcare>-->
      <!--        <b-img :src="img" fluid alt="" />-->
      <!--      </b-tab>-->
    </b-tabs>
    <div class="d-flex justify-content-end mt-2 mb-50">
      <b-button class="mr-1" variant="success" @click="insertImage"> Add Image</b-button>
      <b-button variant="outline-secondary" @click="show = false">Close modal</b-button>
    </div>
  </b-modal>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
// import axios from 'axios'
import {BButton, BFormInput, BModal, BTab, BTabs} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BTabs,
    BTab,
    BFormInput,
    BButton,
  },
  data() {
    return {
      imageSrc: [],
      singleImageSrc: '',
      command: null,
      show: false,
      tab: 1,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        addRemoveLinks: true,
        resizeQuality: 1,
        dictDefaultMessage: 'UPLOAD A FILE',
      },
      img: '',
      isDragging: false,
      files: [],
    }
  },
  // computed: {
  //     validImage() {
  //         return (
  //             // this.imageSrc.match(/unsplash/) !== null ||
  //             // this.imageSrc.match(/\.(jpeg|jpg|gif|png)$/) != null
  //         );
  //     }
  // },
  methods: {
    onDrop(e) {
      e.preventDefault()
      this.$refs.file.files = e.dataTransfer.files
      this.onChange()
      this.isDragging = false
    },
    dragover(e) {
      e.preventDefault()
      this.isDragging = true
    },
    dragleave() {
      this.isDragging = false
    },
    onChange() {
      this.files = [...this.$refs.file.files]
    },
    remove(i) {
      this.files.splice(i, 1)
    },
    generateURL(file) {
      let fileSrc = URL.createObjectURL(file)
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc)
      }, 1000)
      return fileSrc
    },
    onSuccess(event) {
      this.img = event.originalUrl
      const data = {
        command: this.command,
        data: {
          src: event.originalUrl + '-/quality/smart/-/format/auto/',
          // alt: "YOU CAN ADD ALT",
          // title: "YOU CAN ADD TITLE"
        },
      }

      this.$emit('onConfirm', data)
      this.closeModal()
    },
    onError(event) {
      console.log('Error: ', event)
    },
    showModal(command) {
      // Add the send command
      this.command = command
      this.show = true
    },
    // vfileUploaded(file) {
    //   this.imageSrc.push({
    //     base64: file.dataURL,
    //   })
    // },
    // fileChange(e) {
    //   const file = this.$refs.file.files[0]
    //   const uploadUrl = `https://httpbin.org/post`
    //   let formData = new FormData()
    //
    //   formData.append('file', this.file)
    //
    //   axios.post(uploadUrl).then(data => {
    //     console.log(data)
    //     // Take the URL/Base64 from `data` returned from server
    //     // alert("Your image has been uploaded to the server");
    //     // alert("NOTE THIS IS A DUMMY DEMO, THERE IS NO BACKEND");
    //
    //     this.imageSrc = 'https://source.unsplash.com/random/400x100'
    //   })
    // },
    insertImage() {
      for (let i = 0; i < this.imageSrc.length; i++) {
        const data = {
          command: this.command,
          data: {
            src: this.imageSrc[i].base64,
            // alt: "YOU CAN ADD ALT",
            // title: "YOU CAN ADD TITLE"
          },
        }

        this.$emit('onConfirm', data)
        if (i + 1 === this.imageSrc.length) this.closeModal()
      }
    },
    closeModal() {
      this.show = false
      this.imageSrc = []
      this.tab = 1
    },
  },
}
</script>

<style lang="scss">
.vue-dropzone {
  border-radius: 8px;
  border: 1px solid #d2d2d2;
  display: flex;
  align-items: center;
  min-height: 120px;
  padding: 0 20px;
  overflow-x: auto;

  .dz-default {
    width: 100%;
  }

  .dz-image-preview {
    background: unset;

    .dz-details,
    .dz-image {
      border-radius: 8px;
    }
  }
}

#dropzone {
  border-radius: 8px;
  border: 1px solid #d2d2d2;
  display: flex;
  align-items: center;
  justify-content: center;
  //font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color 0.2s linear;
  min-height: 100px;
  padding: 20px;

  &.hover-dropzone {
    background-color: #f6f6f6;
  }

  .preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .preview-card {
      display: flex;
      align-items: flex-start;
      flex-grow: 1;
      border-radius: 8px;
      border: 1px solid #d2d2d2;
      position: relative;
      max-width: 150px;
      min-width: 100px;

      .preview-img {
        border-radius: 6px 6px 0 0;
        overflow: hidden;
        width: 100%;
        height: 120px;
        object-fit: cover;
      }

      .preview-text {
        font-size: 10px;
        line-height: 1.5;
      }

      button {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 0 2px;
        color: #777;
        border-radius: 4px;
        border: 1px solid #d2d2d2;
      }
    }
  }
}

#dropzone .dz-preview {
  width: 160px;
  display: inline-block;
}

#dropzone .dz-preview .dz-image {
  width: 80px;
  height: 80px;
  margin-left: 40px;
  margin-bottom: 10px;
}

#dropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-size: contain;
}

#dropzone .dz-preview .dz-image > img {
  width: 100%;
}

#dropzone .dz-preview .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}

#dropzone .dz-success-mark,
.dz-error-mark,
.dz-remove {
  display: none;
}
</style>
