export const dashboard = {
  namespaced: true,

  state: {
    weather: null,
  },

  mutations: {
    GET_WEATHER(state, data) {
      state.weather = data
    },
  },
}
