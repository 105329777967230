<template>
  <div class="modal-filter">
    <b-modal
      v-model="filter"
      :cancel-title="$t('Cancel')"
      :ok-title="$t('Accept')"
      centered
      content-class="tw-rounded-xl tw-p-2"
      hide-footer
      hide-header
      hide-header-close
      @show="getSprintsForProject"
    >
      <div class="tw-flex tw-items-center tw-justify-between tw-mb-8">
        <h4 class="tw-mb-0 tw-text-gray-700 tw-font-bold">{{ $t('Filter') }}</h4>
        <div class="tw-flex tw-items-center tw-gap-x-4">
          <font-ai
            v-if="project !== null ? project.title !== '' || $store.state.board.isMyTasks : null"
            v-tippy="{content: $t('Clear filter'), delay: [500, 0]}"
            class="tw-cursor-pointer tw-outline-0 tw-text-gray-600 hover:tw-text-blue-400 tw-transition tw-h-3.5"
            icon="fa-solid fa-filter-circle-xmark"
            @click="clearFilter"
          />
          <font-ai
            v-tippy="{content: $t('Close'), delay: [500, 0]}"
            class="tw-cursor-pointer tw-outline-0 tw-text-gray-600 hover:tw-text-gray-400 tw-transition tw-h-5"
            icon="fa-solid fa-xmark"
            @click="filter = false"
          />
        </div>
      </div>

      <div class="mb-50 tw-flex tw-flex-col tw-gap-y-3">
        <div class="d-flex justify-content-between align-items-center mr-custom tw-text-gray-700 tw-border-none">
          {{ $t("I'm a assignee") }}
          <b-form-checkbox v-model="isMyTasks" class="mr-0" name="check-button" size="md" switch></b-form-checkbox>
        </div>

        <div class="d-flex justify-content-between align-items-center mr-custom tw-text-gray-700 tw-border-none">
          {{ $t("I'm a performer") }}
          <b-form-checkbox v-model="isImPerformer" class="mr-0" name="check-button" switch></b-form-checkbox>
        </div>

        <div
          v-if="companies || companies.length > 1"
          class="d-flex justify-content-between align-items-center tw-border-none"
        >
          <span class="mr-1 tw-text-gray-700 tw-font-normal">{{ $t('Company') }}</span>
          <UIDropdown
            :value="companies.find(el => el.id === selectCompany)"
            :values="companies"
            position="tw-right-0"
            title-button="Company"
            @on-select="selectCompany = $event"
          />
        </div>

        <div class="d-flex justify-content-between align-items-center tw-border-none">
          <span class="mr-1 tw-text-gray-700 tw-font-normal">{{ $t('Project') }}</span>
          <UIDropdown
            :value="project"
            :values="projects"
            position="tw-right-0"
            title-button="Project"
            @on-select="selectProject"
          />
        </div>

        <div v-if="sprints.length > 0" class="d-flex justify-content-between align-items-center tw-border-none">
          <span class="mr-1 tw-text-gray-700">{{ $t('Sprint') }}</span>
          <UIDropdown
            :value="sprint"
            :values="sprints.map(el => ({title: `#${el.name} ${el.goal}`, value: el.id}))"
            position="tw-right-0"
            title-button="Sprint"
            @on-select="selectSprint"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BFormCheckbox, BModal} from 'bootstrap-vue';
import store from '@/store';
import SprintsService from '@/services/sprints.service';
import router from '@/router';
import UIDropdown from '@/components/UIDropdown.vue';
import UserService from '@/services/user.service';
import handlerRoles from '@/libs/acl/handlerRoles';

export default {
  name: 'filterProject',
  components: {
    UIDropdown,
    BModal,
    BFormCheckbox,
  },
  data() {
    return {
      sprints: [],
    };
  },
  computed: {
    isProject() {
      return Object.hasOwn(this.project, 'id');
    },
    filter: {
      get: () => store.state.other.showFilter,
      set: val => {
        store.commit('other/changeViewFilter', val);
      },
    },
    isImPerformer: {
      get: () => store.state.board.isImPerformer,
      set: val => {
        localStorage.setItem('is_im_performer', JSON.stringify(val));
        store.commit('board/CHANGE_STATUS_IM_PERFORMER', val);
      },
    },
    isMyTasks: {
      get: () => store.state.board.isMyTasks,
      set: val => {
        localStorage.setItem('is_my_tasks', JSON.stringify(val));
        store.commit('board/CHANGE_STATUS_MY_TASKS', val);
      },
    },
    project: {
      get: () => store.state.osBoard.selectedProject,
      set: val => {
        localStorage.setItem('selected_project', JSON.stringify(val));
        store.commit('osBoard/SET_PROJECT', val);
      },
    },
    sprint: {
      get: () => {
        return store.state.osBoard.selectedSprint;
      },
      set: val => {
        localStorage.setItem('selected_filter_sprint', JSON.stringify(val));
        store.commit('osBoard/SET_SPRINT', val);
      },
    },
    projects() {
      return store.state.osBoard.projects;
    },
    companies() {
      return store.state.osBoard.user.company.map(el => ({title: el.name, id: el.id}));
    },
    selectCompany: {
      get: () => store.state.osBoard.session.company,
      set: async val => {
        const data = {...store.state.osBoard.session, company: val.id};
        await UserService.setSession(data);
        await UserService.getUserProfile().then(async r => {
          localStorage.removeItem('selected_project');
          localStorage.removeItem('selected_filter_sprint');
          store.commit('osBoard/CLEAR_PROJECT');
          store.commit('osBoard/GET_USER', r.data);
          handlerRoles(r.data.role_name);
          await UserService.getUsersForProject().then();
          await UserService.getSession().then(r => {
            store.commit('osBoard/SET_SESSION', r.data);
          });
          await router.push('/');
        });
      },
    },
  },
  watch: {
    selectCompany() {
      this.getSprintsForProject();
    },
  },
  mounted() {
    const sprint = JSON.parse(localStorage.getItem('selected_filter_sprint'));
    if (sprint) this.sprint = sprint;
  },
  methods: {
    clearFilter() {
      this.$store.commit('osBoard/CLEAR_PROJECT');
      this.$store.commit('board/CHANGE_STATUS_MY_TASKS', false);
      this.$store.commit('board/GET_STATUSES', []);
      this.sprint = {
        goal: '',
        name: '',
      };
    },
    getSprintsForProject() {
      if (localStorage.getItem('access_token') && this.isProject) {
        SprintsService.getSprintsForProject(this.project.id).then(r => {
          if (r.status === 200) {
            this.sprints = r.data;
            this.$store.commit('osBoard/SET_SPRINTS', r.data);
          }
        });
      }
    },
    selectProject(e) {
      localStorage.removeItem('selected_filter_sprint');
      if (router.history.current.path !== '/login') this.getSprintsForProject();
      this.project = e;
      this.sprint = {
        goal: '',
        name: '',
      };
    },
    selectSprint(e) {
      this.sprint = e;
      localStorage.setItem('selected_filter_sprint', JSON.stringify(e));
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-table {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 15px 0;
  align-items: center;

  p {
    margin-bottom: 0;
  }
}

.filter-clear {
  font-size: 12px;
  color: rgba(220, 53, 69, 0.5);
  cursor: pointer;

  &:hover {
    color: rgba(220, 53, 69, 0.8);
  }
}

.modal-filter {
  color: #1ab7ea;

  .v-select {
    .vs__selected {
      margin-top: 3px !important;
    }
  }
}
</style>

<style lang="scss">
.mr-custom .custom-switch .custom-control-label {
  padding-left: 3rem;
  line-height: 1.7rem;
}
</style>
