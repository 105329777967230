export const other = {
  namespaced: true,

  state: {
    colors: [
      '#fb923c',
      '#facc15',
      '#a3e635',
      '#34d399',
      '#2dd4bf',
      '#38bdf8',
      '#60a5fa',
      '#818cf8',
      '#a78bfa',
      '#f472b6',
    ],
    colors2: [
      '#3f51b5',
      '#4caf50',
      '#ff9800',
      '#2196f3',
      '#8bc34a',
      '#cddc39',
      '#9c27b0',
      '#00bcd4',
      '#673ab7',
      '#009688',
      '#ffc107',
      '#607d8b',
      '#e91e63',
      '#3f51b5',
      '#4caf50',
      '#ff9800',
      '#2196f3',
      '#8bc34a',
      '#cddc39',
      '#9c27b0',
      '#00bcd4',
      '#673ab7',
      '#009688',
      '#ffc107',
      '#607d8b',
      '#e91e63',
    ],
    showFilter: false,
    showCreateOther: false,
  },

  mutations: {
    changeViewFilter(state, data) {
      state.showFilter = data;
    },
    changeCreateOther(state, data) {
      state.showCreateOther = data;
    },
  },
};
