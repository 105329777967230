<template>
  <div v-if="!readonly" class="mb-1 top-menu">
    <b-button-group>
      <b-button
        v-tippy="{delay: [800, 0]}"
        :disabled="!editor.can().undo()"
        class="history-back"
        content="History Back"
        @click="editor.chain().focus().undo().run()"
      >
        <RiReplyFill />
      </b-button>
      <b-button
        v-tippy="{delay: [800, 0]}"
        :disabled="!editor.can().redo()"
        class="history-next"
        content="History Next"
        @click="editor.chain().focus().redo().run()"
      >
        <RiShareForwardFill />
      </b-button>
      <b-button
        v-tippy="{delay: [800, 0]}"
        :class="{
          'is-active': editor.isActive({textAlign: 'left'}),
        }"
        content="Align Left"
        @click="editor.chain().focus().setTextAlign('left').run()"
      >
        <RiAlignLeft />
      </b-button>
      <b-button
        v-tippy="{delay: [800, 0]}"
        :class="{
          'is-active': editor.isActive({
            textAlign: 'center',
          }),
        }"
        content="Align Center"
        @click="editor.chain().focus().setTextAlign('center').run()"
      >
        <RiAlignCenter />
      </b-button>
      <b-button
        v-tippy="{delay: [800, 0]}"
        :class="{
          'is-active': editor.isActive({
            textAlign: 'right',
          }),
        }"
        content="Align Right"
        @click="editor.chain().focus().setTextAlign('right').run()"
      >
        <RiAlignRight />
      </b-button>
      <b-button
        v-tippy="{delay: [800, 0]}"
        :class="{
          'is-active': editor.isActive({
            textAlign: 'justify',
          }),
        }"
        content="Align Justify"
        @click="editor.chain().focus().setTextAlign('justify').run()"
      >
        <RiAlignJustify />
      </b-button>
      <div class="separator-menu"></div>
      <b-button
        v-tippy="{delay: [800, 0]}"
        :class="{'is-active': editor.isActive('taskList')}"
        content="setImage"
        @click="editor.chain().focus().toggleTaskList().run()"
      >
        <RiListCheck2 />
      </b-button>
      <b-button
        v-tippy="{delay: [800, 0]}"
        :disabled="!editor.can().sinkListItem('taskItem')"
        content="setImage"
        @click="editor.chain().focus().sinkListItem('taskItem').run()"
      >
        <RiIndentIncrease />
      </b-button>
      <b-button
        v-tippy="{delay: [800, 0]}"
        :disabled="!editor.can().liftListItem('taskItem')"
        content="setImage"
        @click="editor.chain().focus().liftListItem('taskItem').run()"
      >
        <RiIndentDecrease />
      </b-button>
      <!--          <button-->
      <!--            @click="editor.chain().focus().toggleCodeBlock().run()"-->
      <!--            :class="{'is-active': editor.isActive('codeBlock')}"-->
      <!--          >-->
      <!--            toggleCodeBlock-->
      <!--          </button>-->
      <!--          <button @click="editor.chain().focus().setCodeBlock().run()" :disabled="editor.isActive('codeBlock')">-->
      <!--            setCodeBlock-->
      <!--          </button>-->

      <uploadcare
        :publicKey="'44c58b3677539a213057'"
        imagesOnly
        tabs="file url"
        @error="$emit('on-error', $event)"
        @success="$emit('on-success', $event)"
      >
        <b-button>
          <RiImageLine />
        </b-button>
      </uploadcare>

      <b-button v-if="showButton" @click="$emit('toggle-resize')">
        <RiImageEditFill />
      </b-button>

      <b-dropdown
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-tippy="{content: 'Templates', delay: [800, 0]}"
        :toggle-attrs="{style: 'border-radius: 0 !important'}"
        right
        no-caret
        text="Templates"
      >
        <template #button-content>
          <RiFileTextLine />
        </template>
        <b-dropdown-item
          v-for="template in templates"
          :key="template.id"
          @click="editor.chain().focus().insertContent(template.value).run()"
        >
          <span>{{ template.text }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-button-group>
  </div>
</template>

<script>
import RiAlignJustify from 'vue-remix-icons/icons/RiAlignJustify.vue'
import RiAlignCenter from 'vue-remix-icons/icons/RiAlignCenter.vue'
import RiImageLine from 'vue-remix-icons/icons/RiImageLine.vue'
import {BButton, BButtonGroup, BDropdown, BDropdownItem} from 'bootstrap-vue'
import RiAlignLeft from 'vue-remix-icons/icons/RiAlignLeft.vue'
import RiIndentIncrease from 'vue-remix-icons/icons/RiIndentIncrease.vue'
import RiFileTextLine from 'vue-remix-icons/icons/RiFileTextLine.vue'
import RiImageEditFill from 'vue-remix-icons/icons/RiImageEditFill.vue'
import RiAlignRight from 'vue-remix-icons/icons/RiAlignRight.vue'
import RiReplyFill from 'vue-remix-icons/icons/RiReplyFill.vue'
import RiListCheck2 from 'vue-remix-icons/icons/RiListCheck2.vue'
import RiShareForwardFill from 'vue-remix-icons/icons/RiShareForwardFill'
import RiIndentDecrease from 'vue-remix-icons/icons/RiIndentDecrease.vue'
import Uploadcare from 'uploadcare-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'TipTapTopMenu',
  components: {
    RiReplyFill,
    BDropdownItem,
    RiAlignRight,
    RiListCheck2,
    RiImageEditFill,
    BDropdown,
    RiFileTextLine,
    BButton,
    RiShareForwardFill,
    RiIndentIncrease,
    RiAlignLeft,
    BButtonGroup,
    RiImageLine,
    RiAlignCenter,
    RiAlignJustify,
    RiIndentDecrease,
    Uploadcare,
  },

  directives: {
    Ripple,
  },

  props: {
    readonly: {
      type: Boolean,
    },
    showButton: {
      type: Boolean,
    },
    editor: {
      type: Object,
    },
    templates: {
      type: Array,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

.top-menu {
  border-radius: 0.4rem;
  padding: 0 5px;
  background: #fff;
  //overflow-x: auto;

  button {
    font-size: 12px;
    border: 0;
    border-radius: 0 !important;
    padding: 5px !important;
    min-width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: unset !important;

    &:active,
    &:focus,
    &:not(.is-active):hover {
      color: $primary !important;
      cursor: pointer;
      background-color: #0095e81f !important;

      svg {
        color: $primary !important;
        fill: $primary !important;
        height: 18px;
      }
    }

    svg {
      color: #9591a6;
      fill: #9591a6;
      height: 18px;
    }
  }

  .history-back,
  .history-next {
    color: #0095e8ff;
    fill: #0095e8ff;
  }

  .history-back[disabled='disabled'],
  .history-next[disabled='disabled'] {
    color: #9591a6;
    fill: #9591a6;
  }
}
</style>
