import Vue from 'vue';
import VueRouter from 'vue-router';
import UserService from '@/services/user.service';
import {console_logger} from '@/assets/scripts/scripts';
import store from '@/store';
import handlerRoles from '@/libs/acl/handlerRoles';
import moment from 'moment/moment';

Vue.use(VueRouter);

const Home = () => import('@/views/Home.vue');
const Projects = () => import('@/views/Projects.vue');
const Project = () => import('@/views/Project.vue');
const Planning = () => import('@/views/Planning/Planning.vue');
const Tasks = () => import('@/views/Tasks/Tasks.vue');
const Task = () => import('@/views/Task.vue');
const BugForTask = () => import('@/views/BugForTask.vue');
const Board = () => import('@/views/Board/Board.vue');
const WalletDetails = () => import('@/views/WalletDetails.vue');
const Gantt = () => import('@/views/WalletDetails.vue');
const Flow = () => import('@/views/Flow.vue');
const SvgTest = () => import('@/views/SvgTest.vue');
const Profile = () => import('@/views/Profile/UsersEdit.vue');
const PersonalInfo = () => import('@/views/form-wizard/FormWizard.vue');
const Login = () => import('@/views/Authentication/Login-v2.vue');
const EmailVerifySent = () => import('@/views/Authentication/EmailVerifySent');
const EmailVerify = () => import('@/views/Authentication/EmailVerify');
const ResetPassword = () => import('@/views/Authentication/ResetPassword-v2.vue');
const ForgotPassword = () => import('@/views/Authentication/ForgotPassword-v2.vue');
const Error404 = () => import('@/views/error/Error404.vue');
const ErrorAccess = () => import('@/views/error/ErrorAccess.vue');
const ApiService = () => import('@/components/APIService/ApiService.vue');
const KnowledgeBaseCategory = () => import('@/components/Knowledge-base/KnowledgeBaseCategory.vue');
const KnowledgeBaseCategoryPublication = () =>
  import('@/components/Knowledge-base/KnowledgeBaseCategoryPublication.vue');
const KnowledgeBaseCategoryPublicationEdit = () =>
  import('@/components/Knowledge-base/KnowledgeBaseCategoryPublicationEdit.vue');
const KnowledgeBaseCategoryPublicationCreate = () =>
  import('@/components/Knowledge-base/KnowledgeBaseCategoryPublicationCreate.vue');
const SprintAnalytics = () =>
  import('@/views/SprintAnalytics.vue');

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0};
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      redirect: '/projects',
      meta: {
        access: 'all',
      },
    },
    {
      path: '/projects',
      name: 'projects',
      component: Projects,
      meta: {
        action: 'read',
        resource: 'Board',
      },
    },
    {
      path: '/project',
      name: 'project',
      component: Project,
      meta: {
        access: 'manager',
      },
    },
    {
      path: '/planning',
      name: 'planning',
      component: Planning,
      meta: {
        access: 'manager',
      },
    },
    {
      path: '/tasks',
      name: 'tasks',
      component: Tasks,
      meta: {
        access: 'manager',
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application tw-pt-[50px] tw-pb-[90px] md:tw-pb-[60px] tw-px-5 md:tw-px-8 tw-flex tw-flex-col',
        navActiveLink: 'tasks',
      },
    },
    {
      path: '/tasks/:filter',
      name: 'tasks-filter',
      component: Tasks,
      meta: {
        access: 'manager',
        navActiveLink: 'tasks',
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application tw-pt-[50px] tw-pb-[90px] md:tw-pb-[60px] tw-px-5 md:tw-px-8 tw-flex tw-flex-col',
      },
      beforeEnter(to, _, next) {
        if (['my-tasks', 'inbox', 'important', 'completed', 'deleted'].includes(to.params.filter)) next();
        else next({name: 'error-404'});
      },
    },
    {
      path: '/tasks/tag/:tag',
      name: 'tasks-tag',
      component: Tasks,
      meta: {
        access: 'manager',
        navActiveLink: 'tasks',
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application tw-pt-[50px] tw-pb-[90px] md:tw-pb-[60px] tw-px-5 md:tw-px-8 tw-flex tw-flex-col',
      },
      beforeEnter(to, _, next) {
        if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next();
        else next({name: 'error-404'});
      },
    },
    {
      path: '/tasks/type/:type',
      name: 'tasks-type',
      component: Tasks,
      meta: {
        access: 'manager',
        navActiveLink: 'tasks',
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application tw-pt-[50px] tw-pb-[90px] md:tw-pb-[60px] tw-px-5 md:tw-px-8 tw-flex tw-flex-col',
      },
      beforeEnter(to, _, next) {
        if (['epic', 'task', 'bug'].includes(to.params.type)) next();
        else next({name: 'error-404'});
      },
    },
    {
      path: '/task/:id',
      name: 'task',
      component: Task,
      meta: {
        access: 'manager',
      },
    },
    {
      path: '/bug/:id',
      name: 'task-bug',
      component: BugForTask,
      meta: {
        access: 'manager',
      },
    },
    {
      path: '/knowledge-base',
      name: 'knowledge-base',
      component: KnowledgeBaseCategory,
      meta: {
        access: 'manager',
      },
    },
    {
      path: '/knowledge-base/read/:slug',
      name: 'knowledge-base-publication',
      component: KnowledgeBaseCategoryPublication,
      meta: {
        access: 'manager',
      },
    },
    {
      path: '/knowledge-base/edit/:slug',
      name: 'knowledge-base-publication-edit',
      component: KnowledgeBaseCategoryPublicationEdit,
      meta: {
        access: 'manager',
      },
    },
    {
      path: '/knowledge-base/create',
      name: 'knowledge-base-create',
      component: KnowledgeBaseCategoryPublicationCreate,
      meta: {
        access: 'manager',
      },
    },
    {
      path: '/board',
      name: 'board',
      component: Board,
      meta: {
        access: 'all',
      },
    },
    {
      path: '/wallet',
      name: 'wallet',
      component: WalletDetails,
      meta: {
        access: 'all',
      },
    },
    {
      path: '/gantt',
      name: 'gantt',
      component: Gantt,
      meta: {
        access: 'manager',
      },
    },
    {
      path: '/flow',
      name: 'flow',
      component: Flow,
      meta: {
        access: 'manager',
      },
    },
    {
      path: '/test',
      name: 'test',
      component: SvgTest,
      meta: {
        access: 'manager',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        access: 'all',
      },
    },
    {
      path: '/personal-info',
      name: 'personal-info',
      component: PersonalInfo,
      meta: {
        access: 'manager',
      },
    },
    {
      path: '/api-service',
      name: 'api-service',
      component: ApiService,
      meta: {
        access: 'manager',
        layout: 'full',
      },
    },
    {
      path: '/analytics/sprint',
      name: 'analytics-sprint',
      component: SprintAnalytics,
      meta: {
        access: 'manager',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        layout: 'full',
      },
    },
    // {
    //   path: '/register',
    //   name: 'register',
    //   component: () => import('@/views/Authentication/Register-v2.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '/sent-email-verify',
      name: 'sent-email-verify',
      component: EmailVerifySent,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify-email',
      name: 'verify-email',
      component: EmailVerify,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: Error404,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-access',
      name: 'error-access',
      component: ErrorAccess,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
const options = {
  publicPages: ['/login', '/register', '/error-404', '/error-access'],
  successPages: ['board', 'home', 'task'],
  blockedUsers: ['developer'],
};

export const getProfile = (timestamp, nextStep) => {
  UserService.getUserProfile()
    .then(r => {
      store.commit('osBoard/GET_USER', r.data);
      store.commit('osBoard/SET_TIMESTAMP', timestamp);
      handlerRoles(r.data.role_name);
      UserService.getUsersForProject().then();
      UserService.getSession().then(r => {
        store.commit('osBoard/SET_SESSION', r.data)
      });
      nextStep();
    })
    .catch(() => {
      nextStep();
    });
};

router.beforeEach((to, from, next) => {
  const timestamp = moment().startOf('D').unix();
  if (
    localStorage.getItem('access_token') !== null &&
    store.state.osBoard.timestamp !== timestamp &&
    !options.publicPages.includes(to.path)
  ) {
    getProfile(timestamp, nextStep);
  } else {
    nextStep();
  }

  function nextStep(role) {
    const authRequired = !options.publicPages.includes(to.path); // Не публичная страница
    const loggedIn = localStorage.getItem('access_token'); // Авторизованный пользователь

    const userIsBlocked = options.blockedUsers.includes(role); // Заблокированный пользователь
    const pageIsAvailable = options.successPages.includes(to.name); // Страница для заблокированных пользователей

    if (authRequired && !loggedIn) {
      // Не зарегистрирован
      next('/login');
    } else if (authRequired) {
      // Зарегистрирован
      // console_logger('--- Зарегистрирован ---')
      if (userIsBlocked) {
        // Заблокирован
        // console_logger('--- Заблокирован ---')
        if (pageIsAvailable) {
          //
          // console_logger('--- Есть доступ к странице ---')
          next();
        } else {
          // console_logger('--- Нет доступа к странице ---')
          next('/error-access');
        }
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

router.afterEach((to, from) => {
  store.commit('osBoard/LOADING', true);

  const project = localStorage.getItem('selected_project');
  const is_my_tasks = localStorage.getItem('is_my_tasks');

  if (project !== 'undefined' && project !== undefined && project !== null) {
    store.commit('osBoard/SET_PROJECT', JSON.parse(project));
  } else {
    store.commit('osBoard/CLEAR_PROJECT');
  }

  if (is_my_tasks !== 'undefined' && is_my_tasks !== undefined && is_my_tasks !== null) {
    store.commit('board/CHANGE_STATUS_MY_TASKS', JSON.parse(is_my_tasks));
  } else {
    store.commit('board/CHANGE_STATUS_MY_TASKS', false);
  }

  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  appLoading.style.display = 'none';
  if (appLoading) {
    appLoading.style.opacity = '0';
    setTimeout(() => {
      appLoading.style.display = 'none';
    }, 1000);
  }
  //
  const appLoadingPage = document.getElementById('loader-page');
  appLoadingPage.style.opacity = '1';

  setTimeout(() => {
    appLoadingPage.style.opacity = '0';
  }, 400);

  // if (!options.publicPages.includes(to.fullPath)) {
  //   UserService.getUserProfile().then(r => {
  //     store.commit('osBoard/GET_USER', {
  //       ...r.data,
  //       ability: {
  //         action: 'manage',
  //         subject: 'all',
  //       },
  //     })
  //     handlerRoles(r.data.role_name)
  //     UserService.getUsersForProject().then()
  //   })
  // }
});

router.onError = function (error) {
  console_logger(`Router Error: ${error}`, 'error');
};

export default router;
