import ability from '@/libs/acl/ability'

const handlerRoles = role => {
  let userAbility
  let userPermissions = []
  if (role === 'developer') {
    userAbility = ['Board', 'Home', 'Tasks', 'Task']
  } else {
    userAbility = ['Projects', 'Tasks', 'Planning', 'Board', 'Home', 'Task', 'Knowledge-base']
    userPermissions.push({
      action: 'edit',
      subject: 'Knowledge-base',
    })
  }
  userAbility.forEach(route => {
    userPermissions.push({
      action: 'read',
      subject: `${route}`,
    })
  })
  ability.update(userPermissions)
  // store.commit('user/setAbility', userPermissions)
}

export default handlerRoles
