import ProjectsService from '@/services/projects.service'

export const projects = {
  namespaced: true,

  state: {
    showModalProject: false,
    projectData: null,
  },

  mutations: {
    SHOW_MODAL(state, data) {
      ProjectsService.getProject(data).then(r => {
        state.projectData = r.data
        state.showModalProject = !state.showModalProject
      })
    },
    SHOW_CHANGE_MODAL(state) {
      state.showModalProject = !state.showModalProject
    },
  },
}
