export const tasks = {
  namespaced: true,

  state: {
    isMyTasks: false,
    loading: false,
  },

  mutations: {
    CHANGE_STATUS_MY_TASKS(state, data) {
      state.isMyTasks = data
    },
    CHANGE_LOADING(state, data) {
      state.loading = data
    },
  },
}
