export const board = {
  namespaced: true,

  state: {
    defaultStatuses: [],
    statuses: [],
    isMyTasks: false,
    isImPerformer: false,
    itemView: 'grid-view',
  },

  mutations: {
    GET_STATUSES(state, data) {
      state.statuses = data
    },
    CHANGE_VIEW(state, data) {
      state.itemView = data
    },
    GET_DEFAULT_STATUSES(state, data) {
      state.defaultStatuses = data
    },
    CHANGE_STATUS_MY_TASKS(state, data) {
      state.isMyTasks = data
    },
    CHANGE_STATUS_IM_PERFORMER(state, data) {
      state.isImPerformer = data
    },
  },
}
