import moment from 'moment/moment';

export const console_logger = (ctx, type) => {
  if (type === 'error') {
    console.log(
      `%cERROR%c ${new Date().toUTCString().slice(17, 25)}`,
      'padding: 2px 4px; background-color: #d86363; color: #FFFFFF; border-radius: 4px',
      'color: #d86363',
      ctx
    );
  } else if (type === 'warn') {
    console.log(
      `%cWARN%c ${new Date().toUTCString().slice(17, 25)}`,
      'padding: 2px 4px; background-color: #df9a4b; color: #FFFFFF; border-radius: 4px',
      'color: #df9a4b',
      ctx
    );
  } else if (type === 'help') {
    console.log(
      `%cHELP%c ${new Date().toUTCString().slice(17, 25)}`,
      'padding: 2px 4px; background-color: #329AF0; color: #FFFFFF; border-radius: 4px',
      'color: #329AF0',
      ctx
    );
  } else {
    console.log(
      `%cINFO%c ${new Date().toUTCString().slice(17, 25)}`,
      'padding: 2px 4px; background-color: #329AF0; color: #FFFFFF; border-radius: 4px',
      'color: #329AF0',
      ctx
    );
  }
};

export const formatTitle = text => {
  if (text) {
    let textArr = text.split('_');
    let newText = '';
    for (let i = 0; i < textArr.length; i++) {
      newText += textArr[i].toUpperCase();
      if (textArr.length !== i) newText += ' ';
    }
    return newText;
  } else {
    return text;
  }
};

export const formatDate = date => {
  return moment(date).format('DD.MM.YYYY');
};

export const closeMainLoader = () => {
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
};

export const connect = () => {
  console_logger('Starting connection to WebSocket Server');
  let connection = new WebSocket(
    // eslint-disable-next-line no-undef
    process.env.VUE_APP_WS + 'ws/notifications/?token=' + localStorage.getItem('access_token')
  );

  connection.onmessage = function (event) {
    let message = JSON.parse(event.data);
    if (message.status === 'connected from django channels') {
    } else {
      // store.commit('terenoi/addNewNote', message.payload)
    }
  };

  connection.onopen = function (event) {
    console_logger('Successfully connected to the echo websocket server...');
  };
};

export const getTimeFromMinutes = mins => {
  let hours = String(Math.trunc(mins / 60)).padStart(2, '0');
  let minutes = String(mins % 60).padStart(2, '0');
  return hours + ':' + minutes;
};
