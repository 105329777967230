import Vue from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'

import {
  faBell,
  faCalendar,
  faCircleXmark,
  faClock,
  faHourglassHalf,
  faStar,
  faTrashCan
} from '@fortawesome/free-regular-svg-icons'

import {
  fa1,
  fa2,
  fa3,
  faAlignLeft,
  faArrowsRotate,
  faArrowsTurnToDots,
  faArrowUpRightFromSquare,
  faBold, faBook,
  faChartGantt,
  faChartLine, faChartSimple,
  faCheckDouble,
  faCloudSunRain,
  faCode, faCoins,
  faDiagramProject, faDollarSign, faEllipsisVertical, faEuroSign, faExpand,
  faFilter,
  faFilterCircleXmark,
  faGraduationCap,
  faGrip,
  faHeading,
  faHighlighter,
  faImage,
  faInbox,
  faItalic,
  faListCheck,
  faListUl,
  faPause,
  faPenToSquare,
  faPlay,
  faQuoteRight,
  faReply, faRotate,
  faRotateLeft, faRubleSign,
  faShare,
  faStop,
  faStrikethrough,
  faTableColumns, faTurkishLira, faWallet,
  faWindowMinimize,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faClock,
  faStar,
  faListCheck,
  faCheckDouble,
  faTrashCan,
  faPenToSquare,
  faChartLine,
  faChartGantt,
  faGrip,
  faTableColumns,
  faDiagramProject,
  faArrowsTurnToDots,
  faXmark,
  faArrowUpRightFromSquare,
  faListCheck,
  faCode,
  faAlignLeft,
  faImage,
  faCloudSunRain,
  faPlay,
  faStop,
  faRotateLeft,
  faPause,
  faBold,
  faItalic,
  faStrikethrough,
  faHighlighter,
  faReply,
  faShare,
  faListUl,
  faQuoteRight,
  faWindowMinimize,
  faHeading,
  faInbox,
  fa1,
  fa2,
  fa3,
  faCalendar,
  faHourglassHalf,
  faFilter,
  faFilterCircleXmark,
  faGraduationCap,
  faArrowsRotate,
  faBell,
  faExpand,
  faBook,
  faRotate,
  faChartSimple,
  faEllipsisVertical,
  faCircleXmark,
  faCoins,
  faDollarSign,
  faTurkishLira,
  faRubleSign,
  faEuroSign,
  faWallet,
)

Vue.component('font-ai', FontAwesomeIcon)
