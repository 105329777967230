import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import osBoard from './os_board'
import {sprints} from '@/store/os_board/sprints.module'
import {board} from '@/store/os_board/board.module'
import {task} from '@/store/os_board/task.module'
import {projects} from '@/store/os_board/projects.module'
import {calendar} from '@/store/os_board/calendar.module'
import {dashboard} from '@/store/os_board/dashboard.module'
import {other} from '@/store/os_board/other.module'
import {tasks} from '@/store/os_board/tasks.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    osBoard,
    board,
    sprints,
    task,
    tasks,
    projects,
    calendar,
    dashboard,
    other,
  },
  strict: process.env.DEV,
})
