import {VueRenderer} from '@tiptap/vue-2';
import tippy from 'tippy.js';
import CommandsList from '@/components/Task/TipTap/Commands/CommandsList.vue';
import MentionList from '@/components/Task/TipTap/Mention/MentionList.vue';
import store from '@/store';

export default {
  render: () => {
    let component;
    let popup;

    return {
      onStart: props => {
        if (props.text === '@') {
          props.items = store.state.task.task.project.members.map(user => {
            return {
              title: user.title,
              id: user.id,
              ...user,
            };
          });

          component = new VueRenderer(MentionList, {
            parent: this,
            propsData: props,
          });
        } else {
          props.items = [
            {
              title: 'Bullet List',
              description: 'Everything looks more serious with a few bullet points.',
              command: ({editor, range}) => {
                editor.chain().focus().deleteRange(range).toggleBulletList().run();
              },
            },
            {
              title: 'Ordered List',
              description: 'If a bullet list doesn’t look serious enough, put some numbers in front of it.',
              command: ({editor, range}) => {
                editor.chain().focus().deleteRange(range).toggleOrderedList().run();
              },
            },
            {
              title: 'Note',
              description: 'This is where you can leave an important note.',
              command: ({editor, range}) => {
                editor.chain().focus().deleteRange(range).insertContent(`<vue-component><p></p></vue-component>`).run();
              },
            },
            {
              title: 'API Note',
              description: 'A note to describe API requests.',
              command: ({editor, range}) => {
                editor
                  .chain()
                  .focus()
                  .deleteRange(range)
                  .insertContent(`<api-component><p><p>Type: <br/>Url: <br/>Body: </p></p></api-component>`)
                  .run();
              },
            },
            {
              title: 'Blockquote',
              description: 'Quoting other people will make you look clever.',
              command: ({editor, range}) => {
                editor.chain().focus().deleteRange(range).toggleBlockquote().run();
              },
            },
            {
              title: 'Code Block Lowlight',
              description: 'Add some colorful syntax highlighting to your code blocks.',
              command: ({editor, range}) => {
                editor.chain().focus().deleteRange(range).setCodeBlock().run();
              },
            },
            {
              title: 'Table',
              description: 'You’re working on something really serious if you need tables inside a text editor.',
              command: ({editor, range}) => {
                editor
                  .chain()
                  .focus()
                  .deleteRange(range)
                  .insertTable({
                    rows: 3,
                    cols: 3,
                    withHeaderRow: true,
                  })
                  .run();
              },
            },
            {
              title: 'Horizontal Rule',
              description: 'Separate what needs to be separated, but use it wisely.',
              command: ({editor, range}) => {
                editor.chain().focus().deleteRange(range).setHorizontalRule().run();
              },
            },
          ];

          component = new VueRenderer(CommandsList, {
            parent: this,
            propsData: props,
          });
        }

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};
