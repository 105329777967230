export const task = {
  namespaced: true,

  state: {
    task: {
      id: null,
      assigned: {
        avatar: null,
      },
      project: {
        title: '',
        members: [],
      },
      description: [],
      status: {
        status: 'new',
      },
      created_by: {
        title: '',
      },
    },
    timeSpent: 0,
    notes: [],
    imageData: null,
    taskView: false,
  },

  mutations: {
    GET_TASK(state, data) {
      state.task = data;
    },
    GET_NOTES(state, data) {
      state.notes = data;
    },
    CHANGE_IMAGE_DATA(state, data) {
      state.imageData = data;
    },
    CHANGE_TASK_VIEW(state, data) {
      state.taskView = data;
    },
    INCREASE_TIME_SPENT(state, data) {
      state.timeSpent = data;
    },
  },
};
