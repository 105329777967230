import axiosIns, {auth} from '@/libs/axios';
import store from '@/store';

class UserService {
  getUserProfile() {
    return axiosIns.get('users/profile/', {headers: auth()});
  }

  updateUserProfile(data) {
    return axiosIns.patch('users/profile/', data, {headers: auth()});
  }

  getUsers() {
    return axiosIns.get('users/', {headers: auth()});
  }

  getUsersForProject() {
    return axiosIns.get('users/', {headers: auth()}).then(r => {
      if (r.status === 200) {
        store.commit(
          'osBoard/GET_USERS',
          r.data.map((el, index) => {
            return {
              ...el,
              color: store.state.other.colors[index % 10],
            };
          })
        );
        store.commit('osBoard/PRELOADER', false);
      }
    });
  }

  setSession(data) {
    return axiosIns.post('users/session/', data, {headers: auth()});
  }

  getSession() {
    return axiosIns.get('users/session/', {headers: auth()});
  }

  getAllHumans() {
    return axiosIns.get('humans', {headers: auth()});
  }
}

export default new UserService();
