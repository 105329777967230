<script>
export default {
  name: 'ViewerImages',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    imageUrl: {
      type: String,
      default: '',
    },
    imageName: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div v-if="show" class="viewer-images">
    <img :alt="imageName" :src="imageUrl" />
  </div>
</template>

<style lang="scss" scoped>
.viewer-images {
  z-index: 999998;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
