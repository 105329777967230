<template>
  <div
    class="tw-z-10 tw-p-1.5 tw-mt-2 tw-min-w-48 tw-rounded-lg tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none tw-min-w-[140px]"
  >
    <template v-if="items.length">
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="{'tw-text-gray-900 tw-bg-gray-100 tw-rounded-md': index === selectedIndex}"
        class="item"
        @click="selectItem(index)"
      >
        <div
          class="tw-text-gray-700 tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-rounded-md"
        >
          <span class="font-weight-bold">{{ item.title }}</span>
          <!--            <span class="text-muted font-small-2">{{ item.description }}</span>-->
        </div>
      </div>
    </template>
    <div v-else class="item">No result</div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
    };
  },

  watch: {
    items() {
      this.selectedIndex = 0;
    },
  },

  methods: {
    onKeyDown({event}) {
      if (event.key === 'ArrowUp') {
        this.upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        this.downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        this.enterHandler();
        return true;
      }

      return false;
    },

    upHandler() {
      this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length;
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    },

    enterHandler() {
      this.selectItem(this.selectedIndex);
    },

    selectItem(index) {
      const item = this.items[index];

      if (item) {
        this.command(item);
      }
    },
  },
};
</script>

<style lang="scss">
.commands-items {
  max-height: unset !important;
  max-width: 300px;
  padding: 0.5rem 0 !important;
  position: relative;
  border-radius: 0.4rem !important;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 10px 20px rgba(0, 0, 0, 0.1);

  .item {
    display: block;
    margin: 0;
    width: 100%;
    text-align: left;
    background: transparent;
    padding: 0.6rem 1.4rem;

    .block-icon {
      border-radius: 3px;
      border: 1px solid #ccc;
      height: 30px;
      width: 30px;
    }

    &.is-selected {
      border-color: #000;
    }
  }
}
</style>
