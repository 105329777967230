<template>
  <div class="w-100 tw-prose">
    <div v-if="editor && isShowTopMenu" class="reader-menu-container">
      <TipTapTopMenu
        :editor="editor"
        :readonly="readonly"
        :show-button="showButton"
        :templates="templates"
        @on-error="onError"
        @on-success="onSuccess"
        @toggle-resize="toggleResize"
      />
    </div>
    <TipTapBubbleMenu :editor="editor" @on-set-link="setLink" />

    <editor-content :editor="editor" />

    <TipTapImages ref="TipTapImages" @onConfirm="addCommand" />

    <b-img :src="img" alt="" class="d-none" />

    <!--    <lr-file-uploader-regular-->
    <!--      :css-src="`https://unpkg.com/@uploadcare/blocks@${PACKAGE_VERSION}/web/file-uploader-regular.min.css`"-->
    <!--      class="uploader-cfg"-->
    <!--    >-->
    <!--      <lr-data-output class="uploader-cfg" hidden use-event @lr-data-output="handleUploaderEvent"></lr-data-output>-->
    <!--    </lr-file-uploader-regular>-->

    <ViewerImages :image-name="viewerImageName" :image-url="viewerImageUrl" :show="showViewer" />

    <div v-if="collaboration" class="editor__footer d-flex justify-content-between">
      <div :class="`editor__status editor__status--${status}`">
        <template v-if="status === 'connected'">
          {{ editor.storage.collaborationCursor.users.length }} user{{
            editor.storage.collaborationCursor.users.length === 1 ? '' : 's'
          }}
          online
        </template>
        <template v-else>offline</template>
      </div>
      <div class="editor__name text-primary">
        {{ currentUser.name }}
      </div>
    </div>
  </div>
</template>

<script>
import {BubbleMenu, Editor, EditorContent} from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import {TextStyle} from '@tiptap/extension-text-style'
import {Color} from '@tiptap/extension-color'
import {Highlight} from '@tiptap/extension-highlight'
import {Table} from '@tiptap/extension-table'
import {TableRow} from '@tiptap/extension-table-row'
import {TableHeader} from '@tiptap/extension-table-header'
import {TableCell} from '@tiptap/extension-table-cell'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import {TextAlign} from '@tiptap/extension-text-align'
import {Link} from '@tiptap/extension-link'
import {Placeholder} from '@tiptap/extension-placeholder'
import {Youtube} from '@tiptap/extension-youtube'
import {Mention} from '@tiptap/extension-mention'
import CharacterCount from '@tiptap/extension-character-count'

import suggestion from '@/components/Task/TipTap/Commands/suggestion'
import Commands from '@/components/Task/TipTap/Commands/commands'

import VueComponent from '@/components/Task/TipTap/Comments/NoteComponent.js'
import APIComponent from '@/components/Task/TipTap/Comments/APIComponent.js'

import css from 'highlight.js/lib/languages/css'
import js from 'highlight.js/lib/languages/javascript'
import ts from 'highlight.js/lib/languages/typescript'
import html from 'highlight.js/lib/languages/xml'
// load all highlight.js languages
import {lowlight} from 'lowlight/lib/core'

import {BButton, BButtonGroup, BDropdown, BDropdownDivider, BDropdownItem, BImg, VBTooltip} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RiH1 from 'vue-remix-icons/icons/RiH1'
import RiH2 from 'vue-remix-icons/icons/RiH2'
import RiH3 from 'vue-remix-icons/icons/RiH3'
import RiInsertColumnLeft from 'vue-remix-icons/icons/RiInsertColumnLeft'
import RiInsertColumnRight from 'vue-remix-icons/icons/RiInsertColumnRight'
import RiInsertRowBottom from 'vue-remix-icons/icons/RiInsertRowBottom'
import RiInsertRowTop from 'vue-remix-icons/icons/RiInsertRowTop'
import RiDeleteRow from 'vue-remix-icons/icons/RiDeleteRow'
import RiDeleteColumn from 'vue-remix-icons/icons/RiDeleteColumn'
import RiMergeCellsHorizontal from 'vue-remix-icons/icons/RiMergeCellsHorizontal'
import RiSplitCellsHorizontal from 'vue-remix-icons/icons/RiSplitCellsHorizontal'
import RiBold from 'vue-remix-icons/icons/RiBold'
import RiItalic from 'vue-remix-icons/icons/RiItalic'
import RiStrikethrough from 'vue-remix-icons/icons/RiStrikethrough'
import RiCodeView from 'vue-remix-icons/icons/RiCodeView'
import RiMarkPenFill from 'vue-remix-icons/icons/RiMarkPenFill'
import RiCloseLine from 'vue-remix-icons/icons/RiCloseLine'
import RiAlignLeft from 'vue-remix-icons/icons/RiAlignLeft'
import RiAlignRight from 'vue-remix-icons/icons/RiAlignRight'
import RiAlignCenter from 'vue-remix-icons/icons/RiAlignCenter'
import RiAlignJustify from 'vue-remix-icons/icons/RiAlignJustify'
import RiReplyFill from 'vue-remix-icons/icons/RiReplyFill'
import RiShareForwardFill from 'vue-remix-icons/icons/RiShareForwardFill'
import RiLink from 'vue-remix-icons/icons/RiLink'
import RiLinkUnlink from 'vue-remix-icons/icons/RiLinkUnlink'
import RiSubscript from 'vue-remix-icons/icons/RiSubscript'
import RiSuperscript from 'vue-remix-icons/icons/RiSuperscript'
import RiHeading from 'vue-remix-icons/icons/RiHeading'
import RiMore2Fill from 'vue-remix-icons/icons/RiMore2Fill'
import RiListCheck2 from 'vue-remix-icons/icons/RiListCheck2.vue'
import RiIndentDecrease from 'vue-remix-icons/icons/RiIndentDecrease.vue'
import RiIndentIncrease from 'vue-remix-icons/icons/RiIndentIncrease.vue'
import RiImageLine from 'vue-remix-icons/icons/RiImageLine.vue'
import RiImageEditFill from 'vue-remix-icons/icons/RiImageEditFill.vue'
import RiFileTextLine from 'vue-remix-icons/icons/RiFileTextLine.vue'
import TipTapImages from '@/components/Task/TipTap/TipTapImages.vue'
import {CodeBlockLowlight} from '@tiptap/extension-code-block-lowlight'
import ViewerImages from '@/components/ViewerImages.vue'

import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import {HocuspocusProvider} from '@hocuspocus/provider'
import ResizableImage from '@/components/Task/TipTap/resizable-image'
import {Dropcursor} from '@tiptap/extension-dropcursor'
import TopMenu from '@/components/Task/TipTap/Menu/TipTapTopMenu.vue'
import TipTapTopMenu from '@/components/Task/TipTap/Menu/TipTapTopMenu.vue'
import TipTapBubbleMenu from '@/components/Task/TipTap/Menu/TipTapBubbleMenu.vue'

const getRandomElement = list => {
  return list[Math.floor(Math.random() * list.length)]
}

lowlight.registerLanguage('html', html)
lowlight.registerLanguage('css', css)
lowlight.registerLanguage('js', js)
lowlight.registerLanguage('ts', ts)

export default {
  components: {
    TipTapBubbleMenu,
    TipTapTopMenu,
    TopMenu,
    ViewerImages,
    BImg,
    TipTapImages,
    EditorContent,
    BubbleMenu,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    RiH1,
    RiH2,
    RiH3,
    RiImageLine,
    RiInsertColumnLeft,
    RiInsertColumnRight,
    RiInsertRowBottom,
    RiInsertRowTop,
    RiDeleteRow,
    RiDeleteColumn,
    RiMergeCellsHorizontal,
    RiSplitCellsHorizontal,
    RiBold,
    RiItalic,
    RiStrikethrough,
    RiCodeView,
    RiMarkPenFill,
    RiCloseLine,
    RiAlignLeft,
    RiAlignRight,
    RiAlignCenter,
    RiAlignJustify,
    RiReplyFill,
    RiShareForwardFill,
    RiLink,
    RiLinkUnlink,
    RiSubscript,
    RiSuperscript,
    RiHeading,
    RiMore2Fill,
    RiListCheck2,
    RiIndentDecrease,
    RiIndentIncrease,
    RiFileTextLine,
    RiImageEditFill,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    collaboration: {
      type: Boolean,
      default: false,
    },
    isShowTopMenu: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem('currentUser')) || {
        name: 'user',
        color: this.getRandomColor(),
      },
      provider: null,
      editor: null,
      status: 'connecting',
      limit: 2000,
      count: 0,
      files: [],
      img: '',
      showViewer: false,
      viewerImageUrl: '',
      viewerImageName: '',
    }
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },
  mounted() {
    let ext = [
      StarterKit.configure({
        history: !this.collaboration,
      }),
      VueComponent,
      APIComponent,
      Commands.configure({
        HTMLAttributes: {
          class: 'commands',
        },
        suggestion,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion,
      }),
      CodeBlockLowlight.configure({
        lowlight,
      }),
      TextStyle,
      Color,
      Highlight.configure({multicolor: true}),
      History,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      Link,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Youtube,
      // Image.configure({
      //   allowBase64: true,
      //   HTMLAttributes: {
      //     class: 'viewer-image',
      //   },
      // }),
      ResizableImage.configure({
        allowBase64: true,
        HTMLAttributes: {
          class: 'reader-img',
        },
        inline: true,
      }),
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Placeholder.configure({
        placeholder: 'Write something …',
      }),
      CharacterCount.configure({
        mode: 'textSize',
      }),
      Dropcursor.configure({
        color: '#0095e8',
        width: 1,
        class: 'dropcursor-class',
      }),
    ]

    if (this.collaboration) {
      this.provider = new HocuspocusProvider({
        url: `ws://127.0.0.1:1234/${this.$route.params.id}`,
        name: `task ${this.$route.params.id}`,
      })

      this.provider.on('status', event => {
        this.status = event.status
      })

      ext = [
        ...ext,
        Collaboration.configure({
          document: this.provider.document,
        }),
        CollaborationCursor.configure({
          provider: this.provider,
          user: this.currentUser,
        }),
      ]
    }

    const f = () => {
      console.log(this)
    }

    this.editor = new Editor({
      content: this.value,
      editable: !this.readonly,
      extensions: ext,
      editorProps: {
        handleKeyDown: (view, event) => {
          if (event.keyCode === 13 && event.altKey) this.$emit('create-message-task')
        },
        // handleDrop: function (view, event, slice, moved) {
        //   if (!moved && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) {
        //     // if dropping external files
        //     let file = event.dataTransfer.files[0] // the dropped file
        //     let filesize = (file.size / 1024 / 1024).toFixed(4) // get the filesize in MB
        //     if ((file.type === 'image/jpeg' || file.type === 'image/png') && filesize < 10) {
        //       // check valid image type under 10MB
        //       // check the dimensions
        //       let _URL = window.URL || window.webkitURL
        //       let img = new Image() /* global Image */
        //       img.src = _URL.createObjectURL(file)
        //       img.onload = () => {
        //         if (this.width > 5000 || this.height > 5000) {
        //           window.alert('Your images need to be less than 5000 pixels in height and width.') // display alert
        //         } else {
        //           // valid image so upload to server
        //           // uploadImage will be your function to upload the image to the server or s3 bucket somewhere
        //           this.uploadImage(file)
        //             .then(response => {
        //               // response is the image url for where it has been saved
        //               // pre-load the image before responding so loading indicators can stay
        //               // and swaps out smoothly when image is ready
        //               let image = new Image()
        //               image.src = response
        //               image.onload = () => {
        //                 // place the now uploaded image in the editor where it was dropped
        //                 const {schema} = view.state
        //                 const coordinates = view.posAtCoords({left: event.clientX, top: event.clientY})
        //                 const node = schema.nodes.image.create({src: response}) // creates the image element
        //                 const transaction = view.state.tr.insert(coordinates.pos, node) // places it in the correct position
        //                 return view.dispatch(transaction)
        //               }
        //             })
        //             .catch(error => {
        //               if (error) {
        //                 window.alert('There was a problem uploading your image, please try again.')
        //               }
        //             })
        //         }
        //       }
        //     } else {
        //       window.alert('Images need to be in jpg or png format and less than 10mb in size.')
        //     }
        //     return true // handled
        //   }
        //   return false // not handled use default behaviour
        // },
      },
      onUpdate: ({editor}) => {
        this.$emit('input', this.editor.getHTML())
        this.$emit('update', this.editor)
        this.count = editor.storage.characterCount.characters()
      },
    })
  },
  computed: {
    // We show that button only if the node is of type 'ResizableImage'
    showButton() {
      return this.editor?.state?.selection?.node?.type?.name === 'ResizableImage'
    },
    // And let the button know what is going on with the img right now
    isDraggable() {
      return this.editor?.state?.selection?.node?.attrs?.isDraggable
    },
    templates() {
      return [
        {
          id: 0,
          text: 'Задача готова, залито на TEST!',
          value: 'Задача готова, залито на <code>TEST</code>!',
        },
        {
          id: 1,
          text: 'Залито на PROD!',
          value: 'Залито на <code>PROD</code>!',
        },
        {
          id: 2,
          text: 'Залито на PROD!',
          value: 'Залито на <code>PROD</code>!',
        },
      ]
    },
  },
  methods: {
    uploadImage(file) {
      // const data = new FormData();
      // data.append('file', file);
      // return axios.post('/documents/image/upload', data);
    },
    toggleResize() {
      this.editor.chain().focus().toggleResizable().run()
    },
    onSuccess(event) {
      this.img = event.originalUrl
      this.editor
        .chain()
        .focus()
        .setImage({src: event.originalUrl + '-/quality/smart/-/format/auto/'})
        .run()
    },
    onError(event) {
      console.log('Error: ', event)
    },
    openModal(command) {
      this.$refs.TipTapImages.showModal(command)
    },
    addCommand(data) {
      this.editor.chain().focus().setImage({src: data.data.src}).run()
      if (data.command !== null && data.command !== undefined) {
        data.command(data.data)
      }
    },
    setContent() {
      this.editor.setContent(this.content)
    },
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      if (url === null) {
        return
      }

      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run()

        return
      }

      this.editor.chain().focus().extendMarkRange('link').setLink({href: url}).run()
    },
    setName() {
      const name = (window.prompt('Name') || '').trim().substring(0, 32)

      if (name) {
        return this.updateCurrentUser({
          name,
        })
      }
    },
    updateCurrentUser(attributes) {
      this.currentUser = {...this.currentUser, ...attributes}
      this.editor.chain().focus().updateUser(this.currentUser).run()

      localStorage.setItem('currentUser', JSON.stringify(this.currentUser))
    },
    getRandomColor() {
      return getRandomElement(['#958DF1', '#F98181', '#FBBC88', '#FAF594', '#70CFF8', '#94FADB', '#B9F18D'])
    },
  },
  beforeDestroy() {
    this.editor.destroy()
    this.provider.destroy()
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.separator-menu {
  border-left: 1px solid #e8e8e8;
  margin: 0 8px;
}

/* Basic editor styles */
.ProseMirror {
  width: 100%;

  &:focus-visible {
    outline: 0;
  }

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
    margin-left: 1rem;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: auto;
  }

  li p {
    margin: 0.2rem 0;
  }

  //h1,
  //h2,
  //h3,
  //h4,
  //h5,
  //h6 {
  //  line-height: 1.1;
  //  @apply tw-text-base;
  //}

  img {
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
    height: auto;
    margin: 0.5rem;

    &[draggable='false'] {
      outline: 3px solid #0095e8;
      cursor: nwse-resize;
    }

    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }

  blockquote {
    padding-left: 1rem;
    border-left: 3px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 1px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  mark {
    background-color: #ffe066;
    padding: 0.125em 0;
    border-radius: 0.25em;
    box-decoration-break: clone;
  }

  pre {
    color: #abb2bf;
    background: #282c34;
    border-radius: 10px;
  }

  pre code {
    display: block;
    overflow-x: auto;
    padding: 1em;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  code {
    padding: 3px 5px;
  }

  .hljs {
    color: #abb2bf;
    background: #282c34;
  }

  .hljs-comment,
  .hljs-quote {
    color: #5c6370;
    font-style: italic;
  }

  .hljs-doctag,
  .hljs-formula,
  .hljs-keyword {
    color: #c678dd;
  }

  .hljs-deletion,
  .hljs-name,
  .hljs-section,
  .hljs-selector-tag,
  .hljs-subst {
    color: #e06c75;
  }

  .hljs-literal {
    color: #56b6c2;
  }

  .hljs-addition,
  .hljs-attribute,
  .hljs-meta .hljs-string,
  .hljs-regexp,
  .hljs-string {
    color: #98c379;
  }

  .hljs-attr,
  .hljs-number,
  .hljs-selector-attr,
  .hljs-selector-class,
  .hljs-selector-pseudo,
  .hljs-template-variable,
  .hljs-type,
  .hljs-variable {
    color: #d19a66;
  }

  .hljs-bullet,
  .hljs-link,
  .hljs-meta,
  .hljs-selector-id,
  .hljs-symbol,
  .hljs-title {
    color: #61aeee;
  }

  .hljs-built_in,
  .hljs-class .hljs-title,
  .hljs-title.class_ {
    color: #e6c07b;
  }

  .hljs-emphasis {
    font-style: italic;
  }

  .hljs-strong {
    font-weight: 700;
  }

  .hljs-link {
    text-decoration: underline;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -khtml-border-radius: 5px;
    border: 1px solid #ced4da;

    tbody {
      tr:not(:last-child) {
        th,
        td {
          border-bottom: 1px solid #ced4da;
        }
      }

      tr {
        th:not(:last-child),
        td:not(:last-child) {
          border-right: 1px solid #ced4da;
        }
      }
    }

    td,
    th {
      min-width: 1em;
      //border: 1px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #2479b929;
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }

  iframe {
    border: 2px solid #000;
    border-radius: 5px;
    min-width: 200px;
    min-height: 200px;
    display: block;
    outline: 0 solid transparent;
  }

  div[data-youtube-video] {
    cursor: move;
    padding-right: 24px;
  }

  .ProseMirror-selectednode iframe {
    transition: outline 0.15s;
    //outline: 6px solid #ece111;
  }

  > p:last-child {
    margin-bottom: 0;
  }
}

.mention {
  border: 1px solid #000;
  border-radius: 0.4rem;
  padding: 0.1rem 0.3rem;
  box-decoration-break: clone;
}

ul[data-type='taskList'] {
  padding: 0;

  p {
    margin: 0;
  }

  li {
    display: flex;
    margin: 8px 0;

    > label {
      display: block;
      position: relative;
      padding-left: 30px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        border-radius: 5px;
        transition: all 0.15s ease-in-out;

        &:after {
          content: '';
          position: absolute;
          display: none;
        }

        &:after {
          left: 7px;
          top: 4px;
          width: 6px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      &:hover input ~ span {
        background-color: #ccc;
      }

      input:checked ~ span {
        background-color: $primary;
      }

      input:checked ~ span:after {
        display: block;
      }
    }

    > div {
      flex: 1 1 auto;
    }
  }
}

hr.ProseMirror-selectednode {
  border-top: 1px solid #68cef8;
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.mention {
  border: 1px solid #0095e8ff;
  border-radius: 0.4rem;
  font-weight: 500;
  color: #0095e8ff;
  padding: 0.1rem 0.3rem;
  box-decoration-break: clone;
}

/* Placeholder (at the top) */
.ProseMirror:not(.ProseMirror-focused) p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.reader-menu-container {
  position: sticky;
  top: -1px;
  z-index: 10;
  background: #fff;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.editor {
  &__status {
    align-items: center;
    border-radius: 5px;
    display: flex;

    &::before {
      background: rgba(#0d0d0d, 0.5);
      border-radius: 50%;
      content: ' ';
      display: inline-block;
      flex: 0 0 auto;
      height: 0.5rem;
      margin-right: 0.5rem;
      width: 0.5rem;
    }

    &--connecting::before {
      background: #616161;
    }

    &--connected::before {
      background: #28c76f;
    }
  }

  &__name {
    button {
      background: none;
      border: none;
      border-radius: 0.4rem;
      color: #0d0d0d;
      font: inherit;
      font-size: 12px;
      font-weight: 600;
      padding: 0.25rem 0.5rem;

      &:hover {
        background-color: #0d0d0d;
        color: #fff;
      }
    }
  }
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #0d0d0d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4em;
  user-select: none;
  white-space: nowrap;
}

.ProseMirror {
  outline: unset unset 0;
}

.floating-menu {
  button {
    font-size: 12px;
    border: 0;
    //border-radius: 5px !important;
    padding: 5px !important;
    min-height: 26px;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: unset !important;

    &:not(.is-active):hover {
      color: $primary;
      cursor: pointer;

      svg {
        color: $primary;
        fill: $primary;
        height: 18px;
      }
    }

    svg {
      color: #6e6b7b;
      fill: #6e6b7b;
      height: 18px;
    }
  }
}
</style>