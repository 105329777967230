import Vue from 'vue'

import axios from 'axios'
import {console_logger} from '@/assets/scripts/scripts'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

const axiosIns = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 0,
  headers: {
    Accept: 'application/json',
  },
})

axiosIns.defaults.headers.post['Content-Type'] = 'application/json'
axiosIns.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axiosIns.defaults.headers.post['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
axiosIns.defaults.headers.post['Access-Control-Allow-Credentials'] = true

export default axiosIns

axiosIns.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const currentUrl = router.history.current.path
    if (currentUrl !== '/login') localStorage.setItem('redirect_url', window.location.pathname)
    if (error.response.status === 401) router.push('/login')
    handlerErrors(error)
    return Promise.reject(error)
  }
)

export const auth = () => {
  let accessToken = localStorage.getItem('access_token')
  return accessToken ? {Authorization: 'Bearer ' + accessToken} : {}
}

export const handlerErrors = ({response, request, message}) => {
  const toastMessage = text => {
    console_logger(text, 'error')
    Vue.$toast(
      {
        component: ToastificationContent,
        props: {
          title: 'Внимание!',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: text,
        },
      },
      {
        position: 'top-center',
      }
    )
  }

  if (response) {
    switch (response.status) {
      case 0:
        toastMessage('Нет доступа к интернету. Проверьте сеть.')
        break
      // case 401:
      //   if (currentUrl === '/login') toastMessage('Данные пользователя не найдены (401).')
      //   break
      case 404:
        console_logger('Запрашиваемая страница не найдена (404).')
        break
      case 500:
        toastMessage('Внутренняя Ошибка Сервера (500).')
        break
      default:
        console_logger('Не перехваченная ошибка. ' + request.responseText, 'error')
    }
  } else if (request) {
    console_logger(request, 'error')
  } else {
    // Произошло что-то при настройке запроса, вызвавшее ошибку
    console_logger(message, 'error')
  }
}
