<template>
  <div v-click-outside="onChangeStatus" class="tw-inline-block tw-text-left tw-relative">
    <div class="relative" @click="onChangeStatus(false)">
      <button
        v-if="type === 'button'"
        id="menu-button"
        :class="!value ? 'tw-text-gray-400' : 'tw-text-gray-500'"
        aria-expanded="true"
        aria-haspopup="true"
        class="tw-inline-flex tw-w-full tw-justify-center tw-gap-x-1.5 tw-rounded-lg tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-400 hover:tw-bg-gray-50 tw-whitespace-nowrap"
        type="button"
      >
        {{ value ? value['title'] : titleButton }}
        <svg
          :class="{
            'tw-rotate-180': !isOpenDropdown,
          }"
          aria-hidden="true"
          class="-tw-mr-1 tw-h-5 tw-w-5 tw-text-gray-400 tw-transition"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            clip-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            fill-rule="evenodd"
          />
        </svg>
      </button>
      <span
        v-else-if="type === 'slot'"
        :class="!value ? 'tw-text-gray-400' : 'tw-text-gray-500'"
        class="tw-cursor-pointer"
        ><slot name="button"
      /></span>
      <span
        v-else-if="type === 'link'"
        :class="!value ? 'tw-text-gray-400' : 'tw-text-gray-500'"
        class="tw-cursor-pointer tw-flex tw-items-center tw-gap-x-1"
      >
        {{ value ? value['title'] : titleButton }}
        <svg
          :class="{
            'tw-rotate-180': !isOpenDropdown,
          }"
          aria-hidden="true"
          class="-tw-mr-1 tw-h-5 tw-w-5 tw-text-gray-400 tw-transition"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            clip-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            fill-rule="evenodd"
          />
        </svg>
      </span>
      <div
        :class="[
          position,
          {
            'tw-transition tw-ease-out tw-duration-100 tw-transform tw-opacity-0 tw-scale-95': !isOpenDropdown,
            'tw-transition tw-ease-in tw-duration-75 tw-transform tw-opacity-100 tw-scale-100': isOpenDropdown,
            hidden: isHidden,
          },
        ]"
        aria-labelledby="menu-button"
        aria-orientation="vertical"
        class="tw-absolute tw-z-10 tw-mt-2 tw-min-w-48 tw-rounded-lg tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none tw-min-w-[140px]"
        role="menu"
        tabindex="-1"
      >
        <div class="tw-p-1.5" role="none">
          <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
          <span
            v-for="value in values"
            :key="value.id"
            class="tw-text-gray-700 tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-rounded-md tw-cursor-pointer tw-whitespace-nowrap"
            @click="
              $emit('input', value);
              $emit('on-select', value);
            "
          >
            {{ value.title }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['on-select', 'input'],
  props: {
    type: {
      type: String,
      default: 'button',
    },
    titleButton: {
      type: String,
      default: 'Select',
    },
    position: {
      type: String,
      default: 'right',
    },
    values: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isHidden: true,
      isOpenDropdown: false,
    };
  },
  methods: {
    async onChangeStatus(outside = true) {
      if (outside && !this.isOpenDropdown) return;
      this.isHidden = false;
      if (this.isOpenDropdown) {
        setTimeout(() => {
          this.isHidden = true;
        }, 100);
      }
      setTimeout(() => {
        this.isOpenDropdown = !this.isOpenDropdown;
      }, 0);
    },
    onSelect(value) {
      this.$emit('on-select', value);
      this.isOpenDropdown = !this.isOpenDropdown;
    },
  },
};
</script>

<style lang="scss" scoped></style>
