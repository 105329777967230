<template>
  <div>
    <b-modal
      v-model="$store.state.projects.showModalProject"
      centered
      hide-footer
      ok-only
      ok-title="Accept"
      title="Project Editing"
      @show="uploadData"
    >
      <b-form-group label="Title" label-for="basicInput">
        <b-form-input id="basicInput" v-model="project.title" placeholder="Title" />
      </b-form-group>
      <b-form-group label="Description" label-for="description">
        <b-form-input id="description" v-model="project.description" placeholder="Description" />
      </b-form-group>
      <b-form-checkbox
        v-model="project['is_internal']"
        class="custom-control-primary mt-1 mb-0"
        name="check-button"
        switch
      >
        Internal project
      </b-form-checkbox>
      <p class="font-medium-1 font-weight-bold mt-50">Members</p>
      <div class="project-members">
        <div v-if="project.members.length !== 0">
          <div
            v-for="(data, index) in project.members"
            :key="index"
            :class="index === 0 ? 'mt-25' : 'mt-1'"
            class="d-flex justify-content-start align-items-center"
          >
            <b-avatar v-if="data.avatar !== ''" :src="data.avatar" class="mr-50" size="35" />
            <b-avatar
              v-if="data.avatar === ''"
              :text="formatFullName(data.title)"
              class="mr-50"
              size="35"
              variant="primary"
            />
            <div class="user-page-info">
              <p class="mb-0">
                {{ data.title }}
              </p>
              <small class="text-muted">{{ data.role }}</small>
            </div>
            <b-button
              v-if="data.id !== $store.state.osBoard.user.id"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="btn-icon ml-auto"
              size="sm"
              variant="danger"
              @click="deleteMember(project.id, data.id, index)"
            >
              <feather-icon icon="UserMinusIcon" />
            </b-button>
            <b-button
              v-if="data.id === project.creator.id"
              v-b-tooltip.hover.v-warning
              class="btn-icon ml-auto"
              size="sm"
              title="Creator"
              variant="outline-warning"
            >
              <feather-icon icon="StarIcon" />
            </b-button>
          </div>
        </div>
        <app-collapse v-if="newMembers.length !== 0" class="mt-1">
          <app-collapse-item title="Add members" variant="primary">
            <div
              v-for="(item, i) in newMembers"
              :key="i"
              :class="i === 0 ? 'mt-50' : 'mt-1'"
              class="d-flex align-items-center"
            >
              <b-avatar v-if="item.avatar !== ''" :src="item.avatar" class="mr-50" size="35" />
              <b-avatar
                v-if="item.avatar === ''"
                :text="formatFullName(item.title)"
                class="mr-50"
                size="35"
                variant="primary"
              />
              <div class="user-page-info">
                <h6 class="mb-0">
                  {{ item.title }}
                </h6>
                <small class="text-muted">{{ item.role }}</small>
              </div>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="btn-icon ml-auto"
                size="sm"
                variant="primary"
                @click="addMember(project.id, item)"
              >
                <feather-icon icon="UserPlusIcon" />
              </b-button>
            </div>
          </app-collapse-item>
        </app-collapse>
      </div>
      <div class="mt-3 d-flex align-items-center justify-content-end">
        <b-button variant="primary" @click="updateProject">Save</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BAvatar, BButton, BFormCheckbox, BFormGroup, BFormInput, BModal, VBTooltip} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import ProjectsService from '@/services/projects.service';
import AppCollapse from '@core/components/app-collapse/AppCollapse';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem';

export default {
  components: {
    BFormCheckbox,
    BModal,
    BFormGroup,
    BFormInput,
    BAvatar,
    BButton,

    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      project: {
        title: '',
        description: '',
        members: [],
        creator: {
          id: null,
        },
      },
      selected: null,
      colors: ['success', 'primary', 'secondary', 'danger', 'warning'],
    };
  },
  computed: {
    newMembers() {
      let users = this.$store.state.osBoard.users;
      let usersArr = this.project.members.map(el => el.id);
      let newMembers = [];
      for (const key in users) {
        if (!usersArr.includes(users[key].id)) newMembers.push(users[key]);
      }
      return newMembers;
    },
  },
  methods: {
    uploadData() {
      this.project = this.$store.state.projects.projectData;
      this.selected = this.$store.state.projects.projectData.members;
    },
    formatFullName(name) {
      return name.split(' ')[0][0] + name.split(' ')[1][0];
    },
    updateProject() {
      ProjectsService.updateProject(this.project.id, {
        title: this.project.title,
        description: this.project.description,
        is_internal: this.project.is_internal,
      }).then(() => {
        this.$store.commit('projects/SHOW_CHANGE_MODAL');
        ProjectsService.getProjects();
      });
    },
    deleteMember(project_id, member_id, i) {
      ProjectsService.deleteMember(project_id, [{id: member_id}]).then(() => {
        this.project.members.splice(i, 1);
        ProjectsService.getProjects();
      });
    },
    addMember(project_id, member) {
      ProjectsService.addMembers(project_id, [{id: member.id}]).then(() => {
        this.project.members.push(member);
        ProjectsService.getProjects();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.b-avatar {
  &:hover {
    cursor: pointer;
    background-color: #c96161ff;
  }
}
</style>

<style lang="scss">
.project-members {
  .card-header,
  .card-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }

  .collapse-icon [data-toggle='collapse']:after {
    margin-top: -1px;
    left: 8.8rem;
  }
}
::v-deep .card {
  box-shadow: unset !important;
}
</style>