<template>
  <bubble-menu :editor="editor" :tippy-options="{duration: 100}" class="bubble-menu">
    <b-button-group>
      <b-dropdown
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-tippy="{delay: [800, 0]}"
        content="Heading"
        no-caret
        right
        text="Markers"
        variant="outline-secondary"
      >
        <template #button-content>
          <RiHeading />
        </template>
        <b-dropdown-item
          :class="{
            'is-active': editor.isActive('heading', {
              level: 1,
            }),
          }"
          @click="editor.chain().focus().toggleHeading({level: 1}).run()"
        >
          <RiH1 />
          <span>Heading 1</span>
        </b-dropdown-item>
        <b-dropdown-item
          :class="{
            'is-active': editor.isActive('heading', {
              level: 2,
            }),
          }"
          @click="editor.chain().focus().toggleHeading({level: 2}).run()"
        >
          <RiH2 />
          <span>Heading 2</span>
        </b-dropdown-item>
        <b-dropdown-item
          :class="{
            'is-active': editor.isActive('heading', {
              level: 3,
            }),
          }"
          @click="editor.chain().focus().toggleHeading({level: 3}).run()"
        >
          <RiH3 />
          <span>Heading 3</span>
        </b-dropdown-item>
      </b-dropdown>
      <b-button
        v-tippy="{delay: [800, 0]}"
        :class="{'is-active': editor.isActive('bold')}"
        content="Bold"
        variant="light-primary"
        @click="editor.chain().focus().toggleBold().run()"
      >
        <RiBold />
      </b-button>
      <b-button
        v-tippy="{delay: [800, 0]}"
        :class="{'is-active': editor.isActive('italic')}"
        content="Italic"
        variant="light-primary"
        @click="editor.chain().focus().toggleItalic().run()"
      >
        <RiItalic />
      </b-button>
      <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" no-caret right text="Other" variant="outline-secondary">
        <template #button-content>
          <RiMore2Fill />
        </template>
        <b-dropdown-item
          v-tippy="{delay: [800, 0]}"
          :class="{'is-active': editor.isActive('strike')}"
          content="Strike"
          @click="editor.chain().focus().toggleStrike().run()"
        >
          <RiStrikethrough />
          <span>Strikethrough</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-tippy="{delay: [800, 0]}"
          :class="{
            'is-active': editor.isActive('subscript'),
          }"
          content="Subscript"
          @click="editor.chain().focus().toggleSubscript().run()"
        >
          <RiSubscript />
          <span>Subscript</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-tippy="{delay: [800, 0]}"
          :class="{
            'is-active': editor.isActive('subscript'),
          }"
          content="Superscript"
          @click="editor.chain().focus().toggleSuperscript().run()"
        >
          <RiSuperscript />
          <span>Superscript</span>
        </b-dropdown-item>
      </b-dropdown>
      <b-button
        v-tippy="{delay: [800, 0]}"
        :class="{'is-active': editor.isActive('code')}"
        content="Code"
        variant="light-primary"
        @click="editor.chain().focus().toggleCode().run()"
      >
        <RiCodeView />
      </b-button>
      <b-button
        v-if="!editor.isActive('link')"
        v-tippy="{delay: [800, 0]}"
        :class="{'is-active': editor.isActive('link')}"
        content="Link"
        variant="light-primary"
        @click="$emit('on-set-link')"
      >
        <RiLink />
      </b-button>
      <b-button
        v-else
        v-tippy="{delay: [800, 0]}"
        content="Unlink"
        variant="light-primary"
        @click="editor.chain().focus().unsetLink().run()"
      >
        <RiLinkUnlink />
      </b-button>

      <div style="padding: 5px; display: flex; align-items: center">
        <input
          v-tippy="{delay: [800, 0]}"
          :value="editor.getAttributes('textStyle').color"
          content="Text Color"
          type="color"
          @input="editor.chain().focus().setColor($event.target.value).run()"
        />
      </div>

      <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" no-caret right text="Markers" variant="outline-secondary">
        <b-dropdown-item
          :class="{
            'is-active': editor.isActive('highlight', {
              color: '#ffc078',
            }),
          }"
          @click="editor.chain().focus().toggleHighlight({color: '#ffc078'}).run()"
        >
          <RiMarkPenFill :style="{fill: '#ffc078 !important'}" />
        </b-dropdown-item>
        <b-dropdown-item
          :class="{
            'is-active': editor.isActive('highlight', {
              color: '#8ce99a',
            }),
          }"
          @click="editor.chain().focus().toggleHighlight({color: '#8ce99a'}).run()"
        >
          <RiMarkPenFill :style="{fill: '#8ce99a !important'}" />
        </b-dropdown-item>
        <b-dropdown-item
          :class="{
            'is-active': editor.isActive('highlight', {
              color: '#74c0fc',
            }),
          }"
          @click="editor.chain().focus().toggleHighlight({color: '#74c0fc'}).run()"
        >
          <RiMarkPenFill :style="{fill: '#74c0fc !important'}" />
        </b-dropdown-item>
        <b-dropdown-item
          :class="{
            'is-active': editor.isActive('highlight', {
              color: '#b197fc',
            }),
          }"
          @click="editor.chain().focus().toggleHighlight({color: '#b197fc'}).run()"
        >
          <RiMarkPenFill :style="{fill: '#b197fc !important'}" />
        </b-dropdown-item>
        <b-dropdown-item
          :class="{
            'is-active': editor.isActive('highlight', {
              color: 'red',
            }),
          }"
          @click="editor.chain().focus().toggleHighlight({color: 'red'}).run()"
        >
          <RiMarkPenFill :style="{fill: '#red'}" />
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          :disabled="!editor.isActive('highlight')"
          @click="editor.chain().focus().unsetHighlight().run()"
        >
          <RiCloseLine />
        </b-dropdown-item>
      </b-dropdown>

      <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" no-caret right text="Table" variant="outline-secondary">
        <b-dropdown-item @click="editor.chain().focus().deleteTable().run()">
          <!-- <mdicon name="table-remove" :height="20"/> -->
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item @click="editor.chain().focus().addColumnBefore().run()">
          <RiInsertColumnLeft />
        </b-dropdown-item>
        <b-dropdown-item @click="editor.chain().focus().addColumnAfter().run()">
          <RiInsertColumnRight />
        </b-dropdown-item>
        <b-dropdown-item @click="editor.chain().focus().deleteColumn().run()">
          <RiDeleteColumn />
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item @click="editor.chain().focus().addRowBefore().run()">
          <RiInsertRowTop />
        </b-dropdown-item>
        <b-dropdown-item @click="editor.chain().focus().addRowAfter().run()">
          <RiInsertRowBottom />
        </b-dropdown-item>
        <b-dropdown-item @click="editor.chain().focus().deleteRow().run()">
          <RiDeleteRow />
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item @click="editor.chain().focus().mergeCells().run()">
          <RiMergeCellsHorizontal />
        </b-dropdown-item>
        <b-dropdown-item @click="editor.chain().focus().splitCell().run()">
          <RiSplitCellsHorizontal />
        </b-dropdown-item>
      </b-dropdown>
    </b-button-group>
  </bubble-menu>
</template>

<script>
import {BubbleMenu} from '@tiptap/vue-2';
import RiSubscript from 'vue-remix-icons/icons/RiSubscript.vue';
import RiLink from 'vue-remix-icons/icons/RiLink.vue';
import RiDeleteColumn from 'vue-remix-icons/icons/RiDeleteColumn.vue';
import RiLinkUnlink from 'vue-remix-icons/icons/RiLinkUnlink.vue';
import RiH2 from 'vue-remix-icons/icons/RiH2.vue';
import RiCodeView from 'vue-remix-icons/icons/RiCodeView.vue';
import RiInsertRowBottom from 'vue-remix-icons/icons/RiInsertRowBottom.vue';
import RiSplitCellsHorizontal from 'vue-remix-icons/icons/RiSplitCellsHorizontal.vue';
import {BButton, BButtonGroup, BDropdown, BDropdownDivider, BDropdownItem, VBTooltip} from 'bootstrap-vue';
import RiInsertRowTop from 'vue-remix-icons/icons/RiInsertRowTop.vue';
import RiMore2Fill from 'vue-remix-icons/icons/RiMore2Fill.vue';
import RiCloseLine from 'vue-remix-icons/icons/RiCloseLine.vue';
import RiH1 from 'vue-remix-icons/icons/RiH1.vue';
import RiInsertColumnLeft from 'vue-remix-icons/icons/RiInsertColumnLeft.vue';
import RiHeading from 'vue-remix-icons/icons/RiHeading.vue';
import RiStrikethrough from 'vue-remix-icons/icons/RiStrikethrough.vue';
import RiInsertColumnRight from 'vue-remix-icons/icons/RiInsertColumnRight.vue';
import Ripple from 'vue-ripple-directive';
import RiH3 from 'vue-remix-icons/icons/RiH3';
import RiMarkPenFill from 'vue-remix-icons/icons/RiMarkPenFill';
import RiBold from 'vue-remix-icons/icons/RiBold';
import RiItalic from 'vue-remix-icons/icons/RiItalic';
import RiMergeCellsHorizontal from 'vue-remix-icons/icons/RiMergeCellsHorizontal';
import RiDeleteRow from 'vue-remix-icons/icons/RiDeleteRow';
import RiSuperscript from 'vue-remix-icons/icons/RiSuperscript';

export default {
  name: 'TipTapBubbleMenu',
  components: {
    BubbleMenu,
    RiInsertColumnRight,
    BDropdown,
    RiStrikethrough,
    RiHeading,
    RiInsertColumnLeft,
    BButtonGroup,
    RiH1,
    RiCloseLine,
    RiH3,
    RiMore2Fill,
    RiMarkPenFill,
    BDropdownItem,
    BDropdownDivider,
    RiBold,
    RiInsertRowTop,
    RiItalic,
    BButton,
    RiSplitCellsHorizontal,
    RiInsertRowBottom,
    RiCodeView,
    RiMergeCellsHorizontal,
    RiH2,
    RiDeleteRow,
    RiLinkUnlink,
    RiDeleteColumn,
    RiLink,
    RiSubscript,
    RiSuperscript,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: {
    editor: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

.bubble-menu {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  padding: 0 5px;

  .dropdown {
    .dropdown-menu {
      min-width: unset;
      align-items: center;

      .dropdown-item {
        justify-content: flex-start;
      }

      span {
        margin-left: 0.3rem;
      }
    }

    button {
      border: 0 !important;
    }

    svg {
      height: 18px;
    }

    .dropdown-item {
      padding: 5px 8px;
      display: flex;
      justify-content: center;
    }
  }

  a,
  button {
    font-size: 12px;
    border: 0;
    padding: 5px !important;
    min-height: 26px;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(.is-active):hover {
      color: $primary !important;
      cursor: pointer;

      svg {
        color: $primary !important;
        fill: $primary !important;
        height: 18px;
      }
    }

    svg {
      color: #6e6b7b !important;
      fill: #6e6b7b !important;
      height: 18px;
    }
  }

  .btn {
    border-radius: 0 !important;
  }

  input[type='color'] {
    -webkit-appearance: none;
    border: none;
    width: 16px;
    height: 16px;
    overflow: hidden;
    padding: 0;
  }

  input[type='color']::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  input[type='color']::-webkit-color-swatch {
    border: none;
  }
}
</style>
