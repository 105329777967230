<template>
  <node-view-wrapper class="comment-component">
    <span class="label" contenteditable="false">
      <img
        :src="require('@/assets/images/icons/circle-info-solid.svg')"
        alt=""
        style="height: 16px; margin-right: 0.4rem"
      />
      {{ $t('Note') }}
    </span>
    <node-view-content class="content" />
  </node-view-wrapper>
</template>

<script>
import {NodeViewContent, nodeViewProps, NodeViewWrapper} from '@tiptap/vue-2';

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },

  props: nodeViewProps,
};
</script>

<style lang="scss" scoped>
.comment-component {
  background: #fff;
  border: 1px solid #0095e8;
  border-radius: 0.5rem;
  margin: 1rem 0;
  position: relative;

  .content {
    background: #f6f6f6;
    margin: 3rem 1rem 1rem !important;
    padding: 0.5rem;
    //border: 1px dashed #0D0D0D20;
    border-radius: 0.5rem;
  }
}

.label {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  background-color: #0095e8;
  font-size: 0.7rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  top: -1px;
  padding: 0 0.5rem 0 0;
  border-radius: 0 0 0.5rem 0.5rem;
}
</style>
