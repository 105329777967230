import axiosIns, {auth} from '@/libs/axios';
import store from '@/store';

class ProjectsService {
  getProjects() {
    return axiosIns.get('project/list/', {headers: auth()}).then(r => {
      if (r.status === 200) {
        store.commit(
          'osBoard/GET_PROJECTS',
          r.data.map((el, index) => {
            return {
              ...el,
              color: store.state.other.colors[index % 10],
            };
          })
        );
        store.commit('osBoard/PRELOADER', false);
      }
    });
  }

  getProject(id) {
    return axiosIns.get('project/' + id + '/', {headers: auth()});
  }

  createProject(data) {
    return axiosIns.post('project/create/', data, {headers: auth()});
  }

  updateProject(id, data) {
    return axiosIns.put('project/update/' + id + '/', data, {
      headers: auth(),
    });
  }

  deleteProject(id) {
    return axiosIns.delete('project/delete/' + id + '/', {headers: auth()}).then(() => {
      this.getProjects().then(() => {});
    });
  }

  addMembers(id, data) {
    return axiosIns.post('project/members/' + id + '/add/', data, {
      headers: auth(),
    });
  }

  deleteMember(id, data) {
    return axiosIns.post('project/members/' + id + '/delete/', data, {
      headers: auth(),
    });
  }
}

export default new ProjectsService();
